<template>
    <div class="hero relative flex items-center">
        <swiper
            v-if="!isLoading && items.length > 0"
            ref="mySwiper"
            :options="swiperOptions"
        >
            <swiper-slide v-for="slider in items" :key="slider.id">
                <div
                    class="home-banner"
                    :style="{ backgroundImage: `url('${slider.image_location}')` }"
                ></div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>

        <slot></slot>
    </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import CmsResources from "../../api/cms";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive,
    },
    data() {
        return {
            isLoading: true,
            items: [],
            swiperOptionsDefault: {
                autoplay: false,
                allowTouchMove: false,
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
                slidesPerView: 1,
                navigation: false,
                loop: true,
                pagination: {
                    el: ".hero .swiper-pagination",
                    clickable: true,
                    type: "bullets",
                },
                // Some Swiper option/callback...
            },
        };
    },
    mounted() {
        // const cmsResources = CmsResources;
        // cmsResources.setAxios();
        // cmsResources
        //     .getSliders()
        //     .then((response) => {
        //         this.banners = response.data;
        //     })
        //     .catch((e) => {
        //         console.log(e);
        //     });

        this.loadItems();
    },
    computed: {
        swiperOptions() {
            let opts = this.swiperOptionsDefault;
            if (this.items.length > 1) {
                opts.allowTouchMove = false;
                opts.autoplay = {
                    delay: 4000,
                    disableOnInteraction: true,
                };
            }
            return opts;
        },
    },
    methods: {
        loadItems() {
            this.isLoading = true;
            this.getItems()
                .then((response) => {
                    this.items = response.data.data;
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
        getItems() {
            let url = `/banner/homeBanner`;
            return this.$httpApi
                .get(url)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
