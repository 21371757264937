import Swal from 'sweetalert2';

const mustLoginAlert = document.querySelector('.must-login');
const errorToast = document.querySelector(".swal-top-error");
const successToast = document.querySelector(".swal-top-success");

if (mustLoginAlert) {
    Swal.fire({
        title: `${mustLoginAlert.getAttribute('data-message')}`,
        icon: 'warning',
        toast: true,
        position: 'top-start',
        showConfirmButton: false,
        timer: 3000
    })
}
if (errorToast) {
    Swal.fire({
        title: `${errorToast.getAttribute("data-message")}`,
        icon: "error",
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000
    });
}
if (successToast) {
    Swal.fire({
        title: `${successToast.getAttribute("data-message")}`,
        icon: "success",
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000
    });
}

