<template>
    <div class="flex text-xs justify-between items-center">
        <div class="flex flex-nowrap space-x-3">
            <div class="flex space-x-px items-center" v-if="bedroom > 0">
                <img src="/img/bed.png" alt="">
                <span :title="`Ruang Tidur: ${bedroom}`" v-text="bedroom"></span>
            </div>
            <div class="flex space-x-1 items-center" v-if="bathroom > 0">
                <img src="/img/showers.png" alt="">
                <span :title="`Kamar Mandi: ${bathroom}`" v-text="bathroom"></span>
            </div>
            <div class="ownership-label" v-if="ownership">
                <img src="/img/certificate.png" alt="">
                <span :title="`Sertifikat: ${ownership}`" v-text="ownership"></span>
            </div>
        </div>

        <div>
            <span class="label" v-text="category" :class="loadClass(category)"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bathroom: {
            type: Number,
            default: 0
        },

        bedroom: {
            type: Number,
            default: 0
        },

        ownership: {
            type: String,
            default: ''
        },

        category: {
            type: String,
            default: ''
        },

    },
    methods: {
        loadClass : function (category) {
            if (category === 'Residential') {
                return 'bg-green-light';
            }else if(category === 'Commercial'){
                return 'bg-red';
            }else{
                return 'bg-orange';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    img{
        height: 18px;
        width: auto;
        display: block;
    }
</style>
