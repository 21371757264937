<template>
    <div class="cb-modal" v-bind:class="{ show : showing }">
        <!-- modal -->
        <div class="cb-modal-template-lg">
            <!-- modal header -->
            <div class="cbm-header">
                <slot name="header"></slot>
                <!-- <button class="close-modal" @click.prevent="close"><i class="fas fa-times"></i></button> -->
            </div>
            <!-- modal body -->
            <div class="cbm-body">
                <slot name="body">
                    default body
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showing: {
            required: true,
            type: Boolean
        }
    },
    methods: {
        close(){
            this.$emit('close');
        }
    }
}
</script>

<style>

</style>
