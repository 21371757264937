<template>
    <div>
        <div v-if="images.length > 0">
            <swiper ref="listingslide" :options="swiperOption">
                <swiper-slide v-for="(image, index) in images" :key="index" class="flex justify-center h-64 bg-gray-200">
                    <img :src="image" alt="" class="h-full w-auto">
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"><i class="fas fa-chevron-left"></i></div>
                <div class="swiper-button-next" slot="button-next"><i class="fas fa-chevron-right"></i></div>
            </swiper>
        </div>
        <div v-else>
            Gambar yang anda masukkan akan di tampilkan disini
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

export default {
    props: [
        'images'
    ],
    data() {
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 10,
                pagination: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
}
</script>

<style>

</style>
