import { render, staticRenderFns } from "./AddsComponent.vue?vue&type=template&id=979d8c08&"
import script from "./AddsComponent.vue?vue&type=script&lang=js&"
export * from "./AddsComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports