<template>
    <section class="section-container">
        <h5 class="section-title title-green">{{ sectionTitle }}</h5>
        <div v-if="isLoading" class="grid grid-cols-4 gap-4">
            <loading-card v-for="i in 4" :key="i"></loading-card>
        </div>
        <div v-else-if="!isLoading && items" class="popular-list relative">
            <swiper ref="populasListsSwiper" :options="swiperOption">
                <swiper-slide
                    v-for="popular in items"
                    :key="popular.PropertyID"
                    class="group"
                >
                    <card :popular="popular" />
                </swiper-slide>
            </swiper>
            <div class="swiper-button-prev outside" slot="button-prev">
                <i class="fas fa-chevron-left"></i>
            </div>
            <div class="swiper-button-next outside" slot="button-next">
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import CardFive from "../Utils/CardInformationFive.vue";
import Card from "./Card.vue";

export default {
    props: [
        // "popularlists",
        "sectionTitle",
        // "token"
    ],
    data() {
        return {
            isLoading: false,
            items: [],
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 10,
                pagination: false,
                navigation: {
                    nextEl: ".popular-list .swiper-button-next",
                    prevEl: ".popular-list .swiper-button-prev",
                },
                breakpoints: {
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                },
            },
        };
    },
    components: {
        Swiper,
        SwiperSlide,
        CardFive,
        Card,
    },
    directives: {
        swiper: directive,
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            this.isLoading = true;
            this.getItems()
                .then((response) => {
                    this.items = response.data.data;
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
        getItems() {
            let url = `/property/getPopular?limit=12&sort_by=ViewCount&sort_direction=DESC`;
            let headers = {};
            if (this.$userToken) {
                headers = {
                    Authorization: `Bearer ${this.$userToken}`,
                };
            }
            return this.$httpApi
                .get(url, headers)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
