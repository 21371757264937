<template>
    <div class="pt-3">
        <div class="grid grid-cols-1 gap-y-4">
            <div class="flex justify-between items-start border-b pb-2">
                <div class="flex md:space-x-2 items-center w-full flex-row">
                    <div
                        class="md:flex items-center border-2 w-14 h-14 justify-center hidden"
                        :class="borderColor + ' ' + textColor"
                    >
                        <PropertiIcon :category="property.EngTypeName" />
                    </div>
                    <div class="flex w-full md:flex-row flex-col space-y-2 md:space-y-0">
                        <div class="w-full md:w-3/4">
                            <p
                                class="text-base md:text-lg font-semibold tracking-tight font-display"
                            >
                                {{ property.TypeName }} {{ property.TTName }}
                                <span class="text-xs"
                                    >Dilihat {{ property.ViewCount }} kali</span
                                >
                            </p>
                            <p class="tracking-tight text-xs md:text-base">
                                sekitar {{ property.Address }}
                            </p>
                        </div>
                        <div class="text-left md:text-right flex-1">
                            <p class="text-sm md:text-base font-semibold tracking-tight">
                                <span v-bind:class="textColor">{{
                                    conCur(property.AskingPrice)
                                }}</span>
                            </p>
                            <p
                                class="test-xs md:text-sm tracking-tight"
                                v-html="property.htmlDivider"
                            ></p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <p class="text-xl font-semibold mb-2" v-bind:class="textColor">
                    {{ property.Name }}
                </p>
                <Slider v-if="propsPhotos != null" :photos="propsPhotos" />
            </div>

            <div>
                <div class="mb-4 flex justify-start">
                    <!-- ShareThis BEGIN -->
                    <div class="sharethis-inline-share-buttons"></div>
                    <!-- ShareThis END -->

                    <!-- Go to www.addthis.com/dashboard to customize your tools -->
                    <!-- <div class="addthis_inline_share_toolbox"></div> -->
                    <!-- ShareThis BEGIN<div class="sharethis-inline-share-buttons"></div>ShareThis END -->
                </div>
                <p
                    class="text-base md:text-xl font-semibold border-b pb-2 mb-2 font-display"
                >
                    <span class="icon overview"></span>Ikhtisar Properti
                </p>

                <div class="flex justify-between">
                    <p class="text-sm md:text-base">
                        Listing oleh
                        <span v-bind:class="textColor">{{ property.SellerName }}</span>
                    </p>
                    <div class="text-right text-sm md:text-base">
                        <p v-bind:class="textColor">ID Listing</p>
                        <p>{{ property.UniqueCode }}</p>
                    </div>
                </div>

                <p
                    class="font-semibold mb-1 text-base md:text-lg font-display"
                    v-bind:class="textColor"
                >
                    Deskripsi
                </p>
                <p
                    class="mb-4 tracking-tight text-sm md:text-base"
                    v-html="property.HtmlDesc"
                ></p>

                <p
                    class="font-semibold mb-1 text-base md:text-lg font-display"
                    v-bind:class="textColor"
                >
                    Spesifikasi
                </p>
                <div class="w-full overflow-x-auto">
                    <div
                        class="py-2 inline-flex items-center divide-x divide-white rounded mb-4 w-max"
                        v-bind:class="bgColor"
                    >
                        <div
                            class="py-px px-4 flex flex-col items-center divide-y-2 divide-transparent"
                            data-tippy
                            data-tippy-content="Ruang tidur"
                            v-cloak
                            v-show="property.Bedroom > 0"
                        >
                            <span class="cb-icon bed"></span>
                            <div
                                class="text-xs text-white font-medium md:font-semibold tracking-tight"
                            >
                                {{ property.Bedroom }}
                            </div>
                        </div>
                        <div
                            class="py-px px-4 flex flex-col items-center divide-y-2 divide-transparent"
                            data-tippy
                            data-tippy-content="Kamar Mandi"
                            v-cloak
                            v-show="property.Bathroom > 0"
                        >
                            <span class="cb-icon shower"></span>
                            <div
                                class="text-xs text-white font-medium md:font-semibold tracking-tight"
                            >
                                {{ property.Bathroom }}
                            </div>
                        </div>
                        <div
                            class="py-px px-4 flex flex-col items-center divide-y-2 divide-transparent"
                            data-tippy
                            data-tippy-content="Lahan Parkir"
                            v-cloak
                            v-show="property.Parking > 0"
                        >
                            <span class="cb-icon parking"></span>
                            <div
                                class="text-xs text-white font-medium md:font-semibold tracking-tight"
                            >
                                {{ property.Parking }}
                            </div>
                        </div>
                        <div
                            class="py-px px-4 flex flex-col items-center divide-y-2 divide-transparent"
                            data-tippy
                            data-tippy-content="Luas Bangunan"
                            v-cloak
                            v-show="property.BuildingSize > 0"
                        >
                            <span class="cb-icon building"></span>
                            <div
                                class="text-xs text-white font-medium md:font-semibold tracking-tight"
                                v-html="
                                    sizingLabel(property.BuildingSize) + ' M<sup>2</sup>'
                                "
                            ></div>
                        </div>
                        <div
                            class="py-px px-4 flex flex-col items-center divide-y-2 divide-transparent"
                            data-tippy
                            data-tippy-content="Luas Unit"
                            v-cloak
                            v-show="property.UnitSize > 0"
                        >
                            <span class="cb-icon building"></span>
                            <div
                                class="text-xs text-white font-medium md:font-semibold tracking-tight"
                                v-html="sizingLabel(property.UnitSize) + ' M<sup>2</sup>'"
                            ></div>
                        </div>
                        <div
                            class="py-px px-4 flex flex-col items-center divide-y-2 divide-transparent"
                            data-tippy
                            data-tippy-content="Luas Tanah"
                            v-cloak
                            v-show="property.LandSize > 0"
                        >
                            <span class="cb-icon lot"></span>
                            <div
                                class="text-xs text-white font-medium md:font-semibold tracking-tight"
                                v-html="sizingLabel(property.LandSize) + ' M<sup>2</sup>'"
                            ></div>
                        </div>
                        <div
                            class="py-px px-4 flex flex-col items-center divide-y-2 divide-transparent"
                            data-tippy
                            data-tippy-content="Sertifikat"
                            v-cloak
                            v-show="property.OwnershipID"
                        >
                            <span class="cb-icon cert"></span>
                            <div
                                class="text-xs text-white font-medium md:font-semibold tracking-tight"
                            >
                                {{ property.OwnershipName }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-span-2 pt-2">
            <div class="w-full h-323px">
                <GmapMap
                    ref="propertyMap"
                    :center="{
                        lat: Number(property.Latitude),
                        lng: Number(property.Longitude),
                    }"
                    :zoom="10"
                    style="width: 100%; height: 100%"
                >
                    <GmapMarker
                        :position="{
                            lat: Number(property.Latitude),
                            lng: Number(property.Longitude),
                        }"
                    />
                </GmapMap>
            </div>
        </div>

        <div class="col-span-2 pt-2">
            <p
                class="font-semibold mb-1 text-base md:text-lg font-display"
                v-bind:class="textColor"
            >
                Bank Rekanan
            </p>
            <BankList :authStatus="authStatus" :property="property" :route="route" />
        </div>
    </div>
</template>

<script>
import Slider from "./SliderListing.vue";
import PropertiIcon from "../Icons/PropertyIcon.vue";
import BankList from "../Banks/BankList.vue";

export default {
    props: ["property", "authStatus", "route"],
    data() {
        return {
            CategoryName: "",
            showBanksModal: false,
            bankClickCount: 0,
            user_token: null,
        };
    },
    components: {
        Slider,
        PropertiIcon,
        BankList,
    },
    computed: {
        typeImage() {
            let path = "/img/type/";
            let type = this.property.TypeName;

            if (type) {
                return home;
            } else {
                return path + "Others.png";
            }
        },
        bgColor: function () {
            let type = this.CategoryName;
            if (type == "Residential") {
                return "bg-green-light";
            }
            if (type == "Commercial") {
                return "bg-red";
            }
            if (type == "Industrial") {
                return "bg-orange";
            }
        },
        borderColor: function () {
            let type = this.CategoryName;
            if (type == "Residential") {
                return "border-green-light";
            }
            if (type == "Commercial") {
                return "border-red";
            }
            if (type == "Industrial") {
                return "border-orange";
            }
        },
        textColor: function () {
            let type = this.CategoryName;
            if (type == "Residential") {
                return "text-green-light";
            }
            if (type == "Commercial") {
                return "text-red";
            }
            if (type == "Industrial") {
                return "text-orange";
            }
        },
        propsPhotos: function () {
            return this.property.photo;
        },
        propsBank: function () {
            return this.property.bank;
        },
        whatsappLoan() {
            const waUrl = `https://wa.me/628119771073?text=Halo+CloseBuy%21+Saya+ingin+cari+tahu+lebih+lanjut+mengenai+informasi+Loan+Bank+pada+property+berikut+ini%3A+%5B${window.location.href}%5D`;
            return waUrl;
        },
    },
    mounted() {
        this.CategoryName = this.property.CategoryName;
        if (this.authStatus.hasOwnProperty("login")) {
            if (this.authStatus.login == true) {
                this.user_token = this.authStatus.token || null;
            }
        }
    },
    methods: {
        conCur: function (number) {
            const RP = (value) =>
                currency(value, {
                    symbol: "Rp. ",
                    precision: 0,
                    decimal: ",",
                    seperator: ".",
                });
            return RP(Math.round(number)).format();
        },
        conCurLabel: function (data) {
            let num = String(parseInt(data)).slice(0, -6);
            return `${num}Jt`;
        },
        sizingLabel: function (number) {
            const SIZE = (value) =>
                currency(value, {
                    symbol: "",
                    precision: 0,
                    seperator: ",",
                });
            return SIZE(number).format();
        },
        panMap: function (params) {
            this.$refs.propertyMap.panTo({
                lat: 47.912867,
                lng: 106.910723,
            });
        },
        dividePrice: function (a = null, b = null, c = null, d = null) {
            if (a) {
                return this.conCur(d ? d / a : c / a) + " / m<sup>2</sup>";
            } else if (b) {
                return this.conCur(d ? d / b : c / b) + " / m<sup>2</sup>";
            } else {
                return "";
            }
        },
    },
};
</script>
