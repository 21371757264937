<template>
    <div class="listing-map-wrapper show">
        <GmapMap
            :options="{
                mapTypeControl: false,
                streetViewControl: false,
                disableDefaultUI: true,
                mapTypeId: 'roadmap',
                styles: mapStyle,
                gestureHandling: gestureHandling,
                minZoom: 12
            }"
            :center="center"
            :zoom="zoom"
            @idle="mapEvent"
            ref="mapRef"
            style="width: 100%; height: 100%">
            <cluster :zoomOnClick="true" ref="cluster">
                <gmap-custom-marker

                    :key="index"
                    v-for="(marker, index) in dataListing"
                    :zIndex="50 + index"
                    :marker="{
                        lat: parseFloat(marker.Latitude),
                        lng: parseFloat(marker.Longitude)
                    }">
                    <mark-style :listing="marker" ref="marker"/>
                </gmap-custom-marker>
            </cluster>
            <gmap-custom-marker
                :key="index"
                v-for="(marker, index) in singleMarker"
                :zIndex="999999999"
                :marker="{
                    lat: parseFloat(marker.Latitude),
                    lng: parseFloat(marker.Longitude)
                }">
                <mark-style :listing="marker" ref="singleMarker"/>
            </gmap-custom-marker>
        </GmapMap>
    </div>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import GmapCluster from "gmap-vue/dist/components/cluster";
import Marker from './Utils/Marker.vue';
import dummydata from '../../data/listing.json';
import {gmapApi} from 'vue2-google-maps';

export default {
    props: {
        singleMarker: {
            type: Array,
            required: false
        },
        dataFiler: {
            type: Object,
            required: false
        }
    },
    data(){
        return {
            dataListing: [],
            gestureHandling: 'auto',
            center: {
                lng: 106.816666,
                lat: -6.200000
            },
            zoom: 14,
            mapStyle: [
                {
                    featureType: "poi",
                    stylers: [{visibility: "off"}]
                },
                {
                    featureType: "landscape",
                    stylers: [{"visibility": "off"}]
                },
                {
                    featureType: "transit",
                    stylers: [{"visibility": "off"}]
                }
            ],
            cluster: null
        }
    },
    components: {
        'gmap-custom-marker': GmapCustomMarker,
        'mark-style': Marker,
        'cluster': GmapCluster
    },
    computed: {
        google: gmapApi
    },
    created(){
        let strCookies = getUserCookieLocation();
        if (strCookies) {
            strCookies = JSON.parse(strCookies);
            this.setCenter(strCookies.lat, strCookies.lng);
        }else{
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    this.setCenter(position.coords.latitude, position.coords.longitude);
                }, err => {
                    this.setCenter(-6.200000, 106.816666);
                });
            } else {
                alert('nolocation')
            }
        }
    },
    beforeMount(){
        this.dataListing = dummydata;
    },
    mounted () {
        this.$refs.mapRef.$mapPromise.then((map) => {
            this.gestureHandling = 'none';
            this.map = map;
        });
    },
    methods: {
        async mapEvent(){
            this.gestureHandling = 'none';
            await this.fetchDataListing().then((result) => {
                this.dataListing = result.data.data
                this.$emit('map_event', this.map, []);
                userCookieLocation(`{
                    "lat": ${this.map.center.lat()},
                    "lng": ${this.map.center.lng()}
                }`);
                this.gestureHandling = 'auto';
            });
            this.$refs.cluster.$clusterPromise.then((cluster) => {
                cluster.styles_ = [
                    {
                        width: 30,
                        height: 30,
                        className: "custom-clustericon-red",
                    },
                    {
                        width: 30,
                        height: 30,
                        className: "custom-clustericon-yellow",
                    },
                    {
                        width: 30,
                        height: 30,
                        className: "custom-clustericon-green",
                    },
                    {
                        width: 20,
                        height: 20,
                        className: "custom-clustericon-yellow custom-clustericon-addred ",
                    },
                    {
                        width: 20,
                        height: 20,
                        className: "custom-clustericon-red custom-clustericon-addgreen",
                    },
                    {
                        width: 20,
                        height: 20,
                        className: "custom-clustericon-yellow custom-clustericon-addgreen",
                    },
                    {
                        width: 30,
                        height: 30,
                        className: "custom-clustericon",
                    }
                ];
                cluster.gridSize_ = 100;
                cluster.clusterClass_ = "";
                let markersComponent = this.$refs.marker;
                cluster.setCalculator(function(markers, numStyles){
                    console.log(markers);
                    var index = 0,
                    //Count the total number of markers in this cluster
                        count = markers.length,
                    //Set total to loop through (starts at total number)
                        total = count;
                        console.log(total);
                });

                this.cluster = cluster;
            });
        },
        setCenter(lat, lng){
            this.center = {
                lat: lat,
                lng: lng
            };
        },
        fetchDataListing(){
            let that = this;
            let config = {
                url : `${process.env.MIX_BASE_URL_API}/property/getAllListingTest`,
                params : {
                    latitude: this.map.center.lat,
                    longitude: this.map.center.lng,
                    radius: {
                        a: this.map.getBounds().getSouthWest().lat(),
                        b: this.map.getBounds().getSouthWest().lng(),
                        c: this.map.getBounds().getNorthEast().lat(),
                        d: this.map.getBounds().getNorthEast().lng(),
                    },
                    property_type: [1,2,3,4,5,6,7,8,9,11,12,13,14,14],
                    property_category: [1,2,3],
                    transaction_type: [1,2],
                    sort_by: this.dataFiler.sort.value,
                    sort_direction: this.dataFiler.sort_direction.value,
                    local: 'local'
                }
            }
            return axios.get(config.url, { params: config.params });
                // .then(function(response){
                //     let listingDataResponse = response.data.data;
                //     that.dataListing = listingDataResponse;
                //     return listingDataResponse;
                // }).catch(function (err) {
                //     alert('Gagal memuat data, silahkan muat ulang halaman ini.!');
                // });
        }
    }
}
</script>

<style>
    .custom-clustericon {
        background: #079547;
        color: #fff;
        border-radius: 100%;
        font-weight: bold;
        font-size: 10px;
        display: flex;
        align-items: center;
    }

    .custom-clustericon::before,
    .custom-clustericon::after {
        content: "";
        display: block;
        position: absolute;
        width: 50%;
        height: 50%;
        text-align: center;
        z-index: -1;

        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background: #ED1C24;
        /* opacity: 0; */
        border-radius: 100%;
        box-shadow: 0px 0px 0px 3px #F7941D,
            0px 0px 0px 6px #079547;
    }

    .custom-clustericon-addred {
        box-shadow: 0px 0px 0px 5px #ED1C24;
        border-radius: 100%;
        content: "";
        display: block;
        position: absolute;
        width: 50%;
        height: 50%;
        text-align: center;
        z-index: -1;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    .custom-clustericon-addyel {
        box-shadow: 0px 0px 0px 5px #F7941D;
        border-radius: 100%;
        content: "";
        display: block;
        position: absolute;
        width: 50%;
        height: 50%;
        text-align: center;
        z-index: -1;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    .custom-clustericon-addgreen {
        box-shadow: 0px 0px 0px 5px #079547;
        border-radius: 100%;
        content: "";
        display: block;
        position: absolute;
        width: 50%;
        height: 50%;
        text-align: center;
        z-index: -1;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    .custom-clustericon-red {
        background: #ED1C24;
        color: #fff;
        border-radius: 100%;
        font-weight: bold;
        font-size: 10px;
        display: flex;
        align-items: center;
    }

    .custom-clustericon-green {
        background: #079547;
        color: #fff;
        border-radius: 100%;
        font-weight: bold;
        font-size: 10px;
        display: flex;
        align-items: center;
    }

    .custom-clustericon-yellow {
        background: #F7941D;
        color: #fff;
        border-radius: 100%;
        font-weight: bold;
        font-size: 10px;
        display: flex;
        align-items: center;
        width: 50%;
        height: 50%;
    }
</style>

