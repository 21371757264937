<template>
<div>
    <div class="dropzone">
        <div class="prose-sm">
            <div class="grid grid-cols-12">
                <div class="col-span-1">1.</div>
                <div class="col-span-11">Silahkan pilih 2-7 foto properti dari sudut terbaik seperti, <strong>Foto property dari luar dengan angle bird eye, Foto Ruang Tamu/Makan, Kamar Tidur, Kamar Mandi dan Floor Plan Property</strong>.</div>
                <div class="col-span-1">2.</div>
                <div class="col-span-11">Ekstensi yang diijinkan: jpeg, jpg, dan png.</div>
                <div class="col-span-1">3.</div>
                <div class="col-span-11">Maksimal ukuran tiap gambar: 10MB.</div>
            </div>
        </div>
        <div class="relative bg-green text-center cursor-pointer p-2 text-white">
            <input type="file" class="opacity-0 absolute inset-0 appearance-none cursor-pointer" name="file-input" multiple="True" @change="handleFileInput" title="Klik atau tarik gambar">
            <div class="block">Pilih gambar</div>
        </div>
    </div>
    <image-list :files="files" :preview="preview" @removed="removeFile" :errorImage="errorImage"/>
</div>

</template>

<script>
import ImageList from './ImageList.vue';
import { validlistingImage } from '../../config/utils';

export default {
    props: [
        'error'
    ],
    data(){
        return {
            files: [],
            preview: [],
            src: [],
            dragging: false,
            errorImage: {
                status: true,
                message: 'Harap pilih gambar'
            }
        }
    },
    components: {
        ImageList
    },
    methods: {
        handleFileInput(e) {
            let files = e.target.files;
            files = e.target.files
            if (!files) {
                return;
            }else{
                ([...files]).forEach(f => {
                    if (!validlistingImage(f)) {
                        this.$toast.open({
                            message: 'Harap masukkan media berupa .jpg, .gif, .png!',
                            type: 'error',
                        });
                        return;
                    }
                    if(f.size >= 10000000){
                        this.$toast.open({
                            message: 'File yang anda masukkan lebih besar dari 10MB!',
                            type: 'error',
                        });
                        return;
                    }
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.preview.push(e.target.result);
                    }
                    this.files.push(f);
                    reader.readAsDataURL(f);
                });
            }
            this.sendToParent();
        },
        removeFile(fileKey){
            this.files.splice(fileKey, 1)
            this.preview.splice(fileKey, 1)
            this.sendToParent();
        },
        sendToParent(){
            let length = this.files.length;
            if (length < 2) {
                this.errorImage.status = true;
                this.errorImage.message = "The minimum item is must be 2";
            }else if(length > 7){
                this.errorImage.status = true;
                this.errorImage.message = "The maximum item is must be 7";
            }else{
                this.errorImage.status = false;
                this.errorImage.message = "";
            }
            this.$emit('selected', this.preview, this.files, length);
        }
    }
}
</script>

<style>

</style>
