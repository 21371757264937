<template>
    <div>
        <p class="font-bold">Pilih area property</p>
        <div
            class="w-full p-2 cursor-pointer border-b border-gray-300"
            v-show="area"
            v-for="ar in area"
            v-bind:key="ar.id"
        >
            <dropdown-item :token="user_token" :item="ar" :type="type"></dropdown-item>
        </div>
    </div>
</template>

<script>
import DropdownItem from "./DropdownItem.vue";

export default {
    props: ["authStatus", "areas", "type"],
    data() {
        return {
            area: null,
            user_token: null,
        };
    },
    components: {
        DropdownItem,
    },
    mounted() {
        if (this.type == "listing") {
            this.area = this.areas.listing;
        } else if (this.type == "wanted") {
            this.area = this.areas.wanted;
        } else if (this.type == "project") {
            this.area = this.areas.project;
        }

        if (this.authStatus.hasOwnProperty("login")) {
            if (this.authStatus.login == true) {
                this.user_token = this.authStatus.token || null;
            }
        }
    },
    watch: {
        type: {
            // the callback will be called immediately after the start of the observation
            immediate: true,
            handler(val, oldVal) {
                if (val == "listing") {
                    this.area = this.areas.listing;
                } else if (val == "wanted") {
                    this.area = this.areas.wanted;
                } else if (val == "project") {
                    this.area = this.areas.project;
                }
                // this.addActive();
            },
        },
    },
    methods: {
        url(coord) {
            return `/${this.type}/${coord.slug}/${coord.lat}/${coord.lng}`;
        },
    },
};
</script>

<style></style>
