<template>
    <div class="container-auth h-screen w-full flex justify-center items-center bg-gray-100 flex-col space-y-4">
        <img src="img/logo_green.png" alt="" width="220" class="mx-auto">

        <div class="card-auth bg-white shadow p-3 md:p-4 w-full md:w-2/5">
            <h1 class="text-center text-xl mb-4 font-display">Masuk ke CloseBuy</h1>

            <div class="grid grid-cols-1 gap-y-4 mb-4">
                <div>
                    <input type="text" class="w-full p-3 border-gray-300 border focus:outline-none text-lg font-body" placeholder="Alamat Surel">
                </div>
                <div>
                    <input type="password" class="w-full p-3 border-gray-300 border focus:outline-none text-lg font-body" placeholder="Password">
                </div>
                <button class="bg-green text-white text-lg text-center py-4">Masuk</button>
            </div>

            <slot></slot>

        </div>

    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
