<template>
    <div class="pt-3 pb-4">
        <div class="grid grid-cols-1 gap-y-3 md:gap-y-6">
            <div
                class="flex md:flex-row flex-col justify-start md:justify-between items-start border-b pb-2"
            >
                <div class="flex space-x-0 md:space-x-2 w-full md:w-3/4">
                    <div class="w-28 md:block hidden">
                        <img :src="property.Logo" alt="" class="w-full rounded-full" />
                    </div>
                    <div>
                        <p
                            class="md:text-lg text-base font-semibold tracking-tight font-display"
                        >
                            {{ property.Name }}
                        </p>
                        <p
                            v-cloak
                            v-show="property.Address"
                            class="tracking-tight text-sm md:text-base"
                        >
                            sekitar {{ property.Address }}
                        </p>
                    </div>
                </div>

                <div class="text-left md:text-right flex-1">
                    <p class="text-base md:text-lg tracking-tight font-display">
                        Mulai dari
                    </p>
                    <p class="md:text-base text-sm tracking-tight text-green-light">
                        {{ conCur(property.AskingPrice) }}
                    </p>
                </div>
            </div>

            <div>
                <p class="text-xl font-semibold mb-2 text-green-light font-display">
                    {{ property.Name }}
                </p>
                <Slider v-if="propsPhotos != null" :photos="propsPhotos" />
            </div>

            <div class="flex justify-start">
                <!-- ShareThis BEGIN -->
                <div class="sharethis-inline-share-buttons"></div>
                <!-- ShareThis END -->

                <!-- Go to www.addthis.com/dashboard to customize your tools -->
                <!-- <div class="addthis_inline_share_toolbox"></div> -->
                <!-- ShareThis BEGIN -->
                <!-- <div class="sharethis-inline-share-buttons"></div> -->
                <!-- ShareThis END -->
            </div>

            <div>
                <p class="text-xl font-semibold border-b pb-2 mb-2 font-display">
                    <span class="icon overview"></span>Ikhtisar Proyek
                </p>
                <div class="flex space-x-2 items-center">
                    <div class="w-32">
                        <img :src="property.DeveloperLogo" alt="" class="w-full" />
                    </div>
                    <p class="text-base font-medium">
                        Proyek dikembangkan oleh
                        <span class="text-green">{{ property.DeveloperName }}</span>
                    </p>
                </div>
            </div>

            <div>
                <p class="font-semibold mb-1 text-lg text-green-light font-display">
                    Deskripsi
                </p>
                <div class="tracking-tight text-base" v-html="property.Description"></div>
            </div>

            <div>
                <p class="font-semibold mb-1 text-lg text-green-light font-display">
                    Keunggulan Utama
                </p>
                <div
                    class="excelence-container"
                    v-html="property.PropertyExcellence"
                ></div>
            </div>

            <div v-if="property.VideoFrame" class="w-full lg:w-3/5">
                <div class="aspect-w-16 aspect-h-9">
                    <iframe
                        :src="youtubeEmbed(property.VideoFrame)"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </div>
            </div>

            <div v-if="property.VirtualViewLink">
                <p class="text-lg font-semibold border-b pb-2 mb-2 font-display">
                    <i class="fas fa-film"></i>Tampilan 3D
                </p>
                <iframe
                    :src="'//' + property.VirtualViewLink"
                    frameborder="0"
                    class="w-full h-323px"
                ></iframe>
            </div>

            <div v-show="property.Brochure" v-cloak>
                <a
                    :href="property.Brochure"
                    target="_blank"
                    class="bg-green py-2 px-4 text-white text-base rounded"
                    ><i class="fas fa-download"></i> Download e-brosur</a
                >
            </div>

            <div v-show="property.Address" v-cloak>
                <p class="font-semibold mb-1 text-lg text-green-light font-display">
                    Alamat Proyek
                </p>
                <div class="tracking-tight text-base">{{ property.Address }}</div>
            </div>

            <div v-if="property.banks.length > 0">
                <p class="font-semibold mb-1 text-lg text-green-light font-display">
                    Bank Rekanan
                </p>
                <div
                    class="grid grid-cols-3 place-items-center w-96"
                    v-if="property.banks.length > 0"
                >
                    <a
                        href="#"
                        class="w-full block p-2"
                        v-for="bank in property.banks"
                        :key="bank.id"
                    >
                        <img :src="loadImage(bank.logo)" class="shadow" alt="" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from "./SliderProject.vue";
import UnitSlider from "./UnitSlider.vue";

export default {
    props: ["property"],
    components: {
        Slider,
    },
    computed: {
        propsPhotos: function () {
            return this.property.photo;
        },
    },
    methods: {
        loadImage: function loadImage(name) {
            return `/img/banks/${name}`;
        },
        conCur: function (number) {
            const RP = (value) =>
                currency(value, {
                    symbol: "Rp. ",
                    precision: 0,
                    decimal: ",",
                    seperator: ".",
                });
            return RP(Math.round(number)).format();
        },
        youtubeEmbed: function youtubeEmbed(url) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            const match = url.match(regExp);
            return match && match[2].length == 11
                ? `https://www.youtube.com/embed/${match[2]}`
                : url;
        },
    },
};
</script>

<style></style>
