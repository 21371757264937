<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" class="property-form">
        <div class="mx-0 py-8">
            <div class="listing-steps stepper">
                <div class="item" v-bind:class="{ done: formIndex >= 1 }">
                    <p class="title">Step 1</p>
                    <p class="font-semibold">Detail Properti</p>
                </div>
                <div class="item" v-bind:class="{ done: formIndex >= 2 }">
                    <p class="title">Step 2</p>
                    <p class="font-semibold">Fitur Properti</p>
                </div>
                <div class="item" v-bind:class="{ done: formIndex >= 3 }">
                    <p class="title">Step 3</p>
                    <p class="font-semibold">Foto Properti</p>
                </div>
                <div class="item" v-bind:class="{ done: formIndex == 4 }">
                    <p class="title">Step 4</p>
                    <p class="font-semibold">Preview</p>
                </div>
            </div>
        </div>

        <!-- <div class="wanted-form-template-one form-step"  v-bind:class="{current: formIndex == 1}"> -->
            <ValidationObserver v-if="formIndex === 1">
                <div class="listing-form-template-one form-step" v-bind:class="{ current: formIndex === 1}">
                    <div class="space-y-4">
                        <div>
                            <ValidationProvider name="Nama Properti" rules="required" v-slot="{ errors }">
                                <label for="property_name" class="mb-0.5 text-sm">Nama Properti <span class="form-helper" data-tippy-content="Judul Iklan. Contoh: Rumah Dijual di Jakarta Selatan - Bergaya American Classic, Full Furnish dan Renovated"></span></label>
                                <input v-model="forms.property_name" id="property_name"  placeholder="Contoh: Rumah Dijual di Jakarta Selatan - Bergaya American Classic, Full Furnish dan Renovated" type="text" class="form-input-property">
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div>
                            <ValidationProvider name="Deskripsi" rules="required" v-slot="{ errors }">
                                <label for="description" class="mb-0.5 text-sm">Deskripsi <span class="form-helper" data-tippy-content="Isi Iklan. Contoh: Dicari untuk di beli rumah! Rumah bergaya arsitektur American Classic ini dibangun di atas lahan 1.000 m2 menghadap Timur. Memiliki 5 Kamar Tidur, 4 Kamar Mandi dan Garasi untuk 3 mobil. Bagian Belakang Rumah terdapat Kolam Renang dan Gazebo."></span></label>
                                <textarea v-model="forms.description" placeholder="Isi Iklan. Contoh: Contoh: Dicari untuk di beli rumah! Rumah bergaya arsitektur American Classic ini dibangun di atas lahan 1.000 m2 menghadap Timur. Memiliki 5 Kamar Tidur, 4 Kamar Mandi dan Garasi untuk 3 mobil. Bagian Belakang Rumah terdapat Kolam Renang dan Gazebo." class="form-input-property" rows="5"></textarea>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div v-if="provinceData">
                            <ValidationProvider name="Provinsi" rules="required" v-slot="{ errors }">
                                <label class="mb-0.5 text-sm" for="province">Pilih Provinsi</label>
                                <multiselect v-model="forms.province" track-by="text" label="text" :options="provinceData" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pilih Provinsi">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div v-cloak v-show="cityData.length > 0">
                            <label class="mb-0.5 text-sm" for="province">Pilih Kota/Kabupaten</label>
                            <ValidationProvider name="Kota/Kabupaten" rules="required" v-slot="{ errors }">
                                <multiselect v-model="forms.city" track-by="text" label="text" :options="cityData" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pilih Kota/Kabupaten">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div v-cloak v-show="districtData.length > 0">
                            <label class="mb-0.5 text-sm" for="district">Pilih Kecamatan</label>
                                <multiselect v-model="forms.district" track-by="text" label="text" :options="districtData" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pilih Kecamatan">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                        </div>
                        <div v-cloak v-show="villageData.length > 0">
                            <label class="mb-0.5 text-sm" for="village">Pilih Desa/Kelurahan</label>
                            <multiselect v-model="forms.village" track-by="text" label="text" :options="villageData" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pilih Desa/Kelurahan">
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                            </multiselect>
                        </div>
                        <div>
                            <ValidationProvider name="Alamat yang di tampilkan" rules="required" v-slot="{ errors }">
                                <label for="prefered_address" class="mb-0.5 text-sm">Alamat Yang Ditampilkan <span class="form-helper" data-tippy-content="Isilah Alamat Pencarian yang akan ditampilkan pada halaman iklan Anda. Silahkan pilih lokasi pada peta di atas"></span></label>
                                <textarea v-model="forms.prefered_address" data-summary="#prefered_address_summary" data-error="Masukkan alamat property" type="text" name="prefered_address" id="prefered_address" class="form-input-property required"></textarea>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row-span-1" v-cloak v-show="forms.prefered_address && forms.province && forms.city">
                        <p class="text-gray-700 underline mb-3">Klik pada peta, untuk menentukan lokasi properti anda atau cari dengan memasukkan nama jalan lokasi yang diinginkan.</p>
                        <div class="map-container relative">
                            <GmapMap
                                    :center="map.center"
                                    :zoom="14"
                                    :options="{
                                        mapTypeControl: false,
                                        scaleControl: false,
                                        fullscreenControl: false,
                                        streetViewControl: false
                                    }"
                                    style="height: 450px"
                                    @click="mark"
                                >
                                <GmapMarker v-for="(marker, index) in map.markers"
                                    :key="index"
                                    :position="marker.position"
                                    :draggable=true
                                    @dragend="mark"
                                    />
                                />
                            </GmapMap>
                            <GmapAutocomplete @place_changed="setPlace" class="form-input-property w-3/4 mx-auto absolute inset-x-0 top-0 mt-2 required"></GmapAutocomplete>
                        </div>
                        <div class="hidden">
                            <textarea type="text" v-model="forms.address" name="address" id="address" class="form-input-property required"></textarea>
                        </div>
                        <div class="hidden">
                            <input type="text" v-model="forms.latitude" name="latitude" id="latitude" class="form-input-property required">
                        </div>
                        <div class="hidden">
                            <input type="text" v-model="forms.longitude" name="longitude" id="longitude" class="form-input-property required">
                        </div>
                    </div>
                </div>
            </ValidationObserver>

            <ValidationObserver v-if="formIndex === 2">
                <div class="listing-form-template-two form-step" v-bind:class="{ current: formIndex === 2}">
                    <div class="row-span-6 grid grid-cols-1 gap-y-4 place-content-start">
                        <div v-cloak v-show="transactionData.length > 0">
                            <label class="mb-0.5 text-sm" for="province">Pilih Tipe Transaksi</label>
                            <ValidationProvider name="Tipe Transaksi" rules="required" v-slot="{ errors }">
                                <multiselect v-model="forms.transaction_type" track-by="text" label="text" :options="transactionData" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Pilih Tipe Transaksi">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label class="mb-0.5 text-sm" for="category">Kategori Properti</label>
                            <ValidationProvider name="Kategori Properti" rules="required" v-slot="{ errors }">
                                <multiselect v-model="forms.category" track-by="text" label="text" :options="categoryData" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Pilih Kategori Properti">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label class="mb-0.5 text-sm" for="category">Tipe Properti</label>
                            <ValidationProvider name="Tipe Properti" rules="required" v-slot="{ errors }">
                                <multiselect v-model="forms.type" track-by="text" label="text" :options="typePropertyData" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Pilih Tipe Properti">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label class="mb-0.5 text-sm" for="ownership">Status Kepemilikan</label>
                            <ValidationProvider name="Status Kepemilikan" rules="required" v-slot="{ errors }">
                                <multiselect class="custom-select" v-model="forms.ownership" track-by="text" label="text" :options="ownershipsData" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Pilih Status Kepemilikan">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="row-span-6">
                        <div class="grid grid-cols-12 gap-y-4 gap-x-4 place-content-start">
                            <div class="col-span-12">
                                <ValidationProvider name="Harga" rules="required|min_value:1" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="ownership">Harga <span class="form-helper" data-tippy-content="Isilah Harga Jual Properti secara keseluruhan bukan per meter pesergi. Harga Sewa disetahunkan"></span></label>
                                    <money v-model="forms.price" v-bind="money" class="form-input-property"></money>
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-6" v-cloak v-show="forms.type && sizing.building_size == 1">
                                <ValidationProvider name="Luas Bangunan" :rules="`${sizing.building_size == 1 ? 'required' : ''}|numeric|min_value:1`" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="building_size">Luas Bangunan m<sup>2</sup></label>
                                    <input placeholder="Ukuran Bangunan (m2)" type="text" v-model="forms.building_size" id="building_size" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-6" v-cloak v-show="forms.type && sizing.land_size == 1">
                                <ValidationProvider name="Luas Tanah" :rules="`${sizing.land_size == 1 ? 'required' : ''}|numeric|min_value:1`" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="land_size">Luas Tanah m<sup>2</sup></label>
                                    <input v-model="forms.land_size" placeholder="Luas Tanah (m2)" type="text" id="land_size" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-4" v-cloak v-show="forms.type && sizing.bedroom == 1">
                                <ValidationProvider name="Kamar Tidur" :rules="`${sizing.bedroom == 1 ? 'required' : ''}|numeric|min_value:1`" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="bedroom">Kamar Tidur</label>
                                    <input v-model="forms.bedroom" placeholder="Jumlah Kamar Tidur" type="text" id="bedroom" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-4" v-cloak v-show="forms.type && sizing.bathroom == 1">
                                <ValidationProvider name="Kamar Mandi" :rules="`${sizing.bathroom == 1 ? 'required' : ''}|numeric|min_value:1`" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="bathroom">Kamar Mandi</label>
                                    <input v-model="forms.bathroom" placeholder="Jumlah Kamar Mandi" type="text" id="bathroom" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-4" v-cloak v-show="forms.type && sizing.carpark == 1">
                                <ValidationProvider name="Lahan Parkir" :rules="`${sizing.carpark == 1 ? 'required' : ''}|numeric|min_value:1`" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="carpark">Lahan Parkir</label>
                                    <input v-model="forms.carpark" placeholder="Jumlah Lahan Parkir" type="text" id="carpark" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-4" v-cloak v-show="forms.type && sizing.room == 1">
                                <ValidationProvider name="Jumlah Ruangan" :rules="`${sizing.room == 1 ? 'required' : ''}|numeric|min_value:1`" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="room">Jumlah Ruangan</label>
                                    <input v-model="forms.room" placeholder="Jumlah Ruangan" type="text" id="room" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-12">
                                <label class="mb-0.5 text-sm" for="listing_by">Di listing Oleh</label>
                                <ValidationProvider name="Listing Oleh" rules="required" v-slot="{ errors }">
                                    <multiselect class="custom-select" v-model="forms.listing_by" track-by="text" label="text" :options="listingByData" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Pilih Pendaftar Listing">
                                        <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                    </multiselect>
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidationObserver>

            <div class="listing-form-template-three form-step" v-bind:class="{ current: formIndex === 3}">
                <div class="col-span-5 md:col-span-3">
                    <swiper :images="preview_images"/>
                </div>
                <div class="col-span-5 md:col-span-2">
                    <image-upload @selected="updateImage"/>
                </div>
            </div>

            <ValidationObserver v-if="formIndex === 4">
                <div class="listing-form-template-four form-step" v-bind:class="{ current: formIndex === 4}">
                    <div class="col-span-5 md:col-span-3">
                        <table class="tables">
                            <Row title="Judul Iklan" :value="forms.property_name || null"/>
                            <Row title="Deskripsi" :value="forms.description || null"/>
                            <Row title="Provinsi" :value="forms.province.text || null"/>
                            <Row title="Kota/Kabupaten" :value="forms.city.text || null"/>
                            <Row title="Kecamatan" :value="forms.district ? forms.district.text : null"/>
                            <Row title="Desa/Kelurahan" :value="forms.village ? forms.village.text : null"/>
                            <Row title="Alamat Yang Di tampilkan" :value="forms.prefered_address || null"/>
                            <Row title="Latitude" :value="forms.latitude || null"/>
                            <Row title="Longitude" :value="forms.longitude || null"/>
                            <Row title="Tipe Transaksi" :value="forms.transaction_type.text || null"/>
                            <Row title="Kategori Properti" :value="forms.category.text || null"/>
                            <Row title="Tipe Properti" :value="forms.type.text || null"/>
                            <Row title="Listing Oleh" :value="forms.listing_by.text || null"/>
                            <Row units="price" title="Harga" :value="forms.price || null"/>
                            <Row units="sizing" title="Ukuran Bangunan" :value="forms.building_size || null"/>
                            <Row units="sizing" title="Ukuran Tanah" :value="forms.land_size || null"/>
                            <Row title="Kamar Tidur" :value="forms.bedroom || null"/>
                            <Row title="Kamar Mandi" :value="forms.bathroom || null"/>
                            <Row title="Lahan Parkir" :value="forms.carpark || null"/>
                            <Row title="Jumlah Ruangan" :value="forms.room || null"/>
                        </table>
                    </div>
                    <div class="col-span-5 md:col-span-2">
                        <swiper :images="preview_images"/>
                    </div>
                </div>
            </ValidationObserver>

            <div class="flex justify-between">
                <button class="prev-button" type="button" @click="prev()" v-cloak v-show="formIndex > 1">Sebelumnya</button>
                <button class="next-button justify-self-end" type="submit" v-if="formIndex < 4">Lanjut</button>
                <button class="submit-button justify-self-end" type="submit" v-else>Submit</button>
            </div>
        </form>
        <loading :loading="loading"/>
        <modal :showing="tosmodal" @close="tosmodal = false">
            <p class="font-semibold text-lg" slot="header">
                Konfirmasi & Persetujuan
            </p>
            <div slot="body">
                    <p class="mb-2">1. Saya setuju dengan Syarat dan Ketentuan serta Kebijakan Privasi yang berlaku</p>
                    <p class="mb-2">2. Saya memberikan konfirmasi bahwa informasi yang saya berikan untuk ditayangkan adalah benar dan tunduk terhadap UU ITE</p>
                    <p class="mb-2">3. Saya setuju untuk menggunakan fitur <b>hubungi kami</b> dan contact <b>CloseBuy Advisor</b> sebagai media komunikasi saya dengan pihak CloseBuy.</p>
                    <p class="mb-2">4. Saya menyetujui untuk menggunakan Escrow Account (Trust Account) CloseBuy maupun pihak yang ditunjuk oleh Calon Pembeli</p>
                    <p class="mb-2">5. Saya mengerti bahwa listing di website CloseBuy adalah Gratis dan berlaku unlimited.</p>
                    <p class="mb-2">6. Saya mengerti dan menyetujui bahwa terdapat service fee / komisi ketika transaksi berhasil dilakukan melalui CloseBuy, Agen CloseBuy, maupun pihak yang berafiliasi lainnya dengan CloseBuy. Informasi melalui besaran biaya komisi (service fee) akan diberikan ketika listing saya disetujui penayangannya.</p>
                <div class="space-x-1 flex">
                    <button type="button" class="bg-green shadow px-2 py-1 text-sm text-white font-semibold mr-3" @click="sendData">Setuju</button>
                    <button type="button" class="bg-gray-200 shadow px-2 py-1 text-sm text-black font-semibold" @click="tosmodal = false">Tidak Setuju</button>
                </div>
            </div>
        </modal>
    </ValidationObserver>

</template>

<script>
import SlimSelect from "slim-select";
import Multiselect from 'vue-multiselect'
import { getAllProvince, getCityByProvince, getDistrictByCity, getVillageByDistrict } from "../../api/zoneApi";
import { getTypesProperty } from "../../api/propertyApi";
import { getPropertyData, getCategoryData, getListingTransaction, getOwnerships, getListingBy } from "../../config/option";
import Row from '../Utils/Table/RowComponent.vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import ImageUpload from "../DropZone/ImageUpload.vue";
import Swiper from "./Slider/SliderImage.vue";
import Loading from '../Utils/LoadingStats.vue';
import Modal from '../Utils/ModalComponent.vue';
import { Money } from "../../plugins/v-money";

export default {
    props: [
        'token'
    ],
    data: () => {
        return{
            iserrorImage: true,
            loading: false,
            tosmodal: false,
            formIndex: 1,
            sizing: {},
            BuildingSizing: 0,
            LandSizing: 0,
            BedroomCounting: 0,
            BathroomCounting: 0,
            CarparkCounting: 0,
            RoomCounting: 0,
            typePropertyAll: [],
            provinceData: [],
            cityData: [],
            districtData: [],
            villageData: [],
            transactionData: [],
            categoryData: [],
            typePropertyData: [],
            typeRequesterData: [],
            ownershipsData: [],
            listingByData: [],
            selectProvinceWanted: null,
            selectCityWanted: null,
            selectDistrictWanted: null,
            selectVillageWanted: null,
            selectTransactionTypeFormWanted: null,
            preview_images: [],
            forms: {
                property_name: null,
                images: [],
                description: null,
                province: null,
                city: null,
                district: null,
                village: null,
                prefered_address: null,
                transaction_type: null,
                category: null,
                type: null,
                listing_by: null,
                ownership: null,
                latitude: null,
                longitude: null,
                address: null,
                price: '0',
                building_size: null,
                land_size: null,
                bedroom: null,
                bathroom: null,
                carpark: null,
                room: null
            },
            map: {
                center: {lat: -6.200000, lng: 106.816666 },
                markers: [],
                place: null
            },
            money: {
                decimal: '',
                thousands: '.',
                prefix: 'Rp. ',
                precision: 0,
                masked: false
            }
        }
    },
    components: {
        Multiselect,
        Row,
        ImageUpload,
        Swiper,
        Loading,
        Modal,
        Money
    },
    computed: {
        province() {
            return this.forms.province || null;
        },
        city(){
            return this.forms.city || null;
        },
        district(){
            return this.forms.district || null;
        },
        village(){
            return this.forms.village || null;
        },
        category(){
            return this.forms.category;
        },
        type(){
            return this.forms.type || null;
        },
        latitude(){
            return this.forms.latitude || null;
        },
        uploadDisabled() {
            return this.forms.images.length === 0;
        },
    },
    watch: {
        province(val) {
            this.forms.city = null;
            this.forms.district = null;
            this.forms.village = null;
            this.cityData = [];
            this.districtData= [];
            this.villageData= [];
            if (val.data) {
                this.provinceSelected(val);
                if (val.data.lat && val.data.long) {
                    let location = {
                        lat: parseFloat(val.data.lat),
                        lng: parseFloat(val.data.long)
                    }
                    this.setMarker(location);
                }
            }
        },
        city(val){
            this.forms.district = null;
            this.forms.village = null;
            this.districtData= [];
            this.villageData= [];
            if (val.data) {
                this.citySelected(val);
                if (val.data.lat && val.data.long) {
                    let location = {
                        lat: parseFloat(val.data.lat),
                        lng: parseFloat(val.data.long)
                    }
                    this.setMarker(location);
                }
            }
        },
        district(val){
            this.forms.village = null;
            this.villageData= [];
            if (val.data) {
                this.districtSelected(val);
                if (val.data.lat && val.data.long) {
                    let location = {
                        lat: parseFloat(val.data.lat),
                        lng: parseFloat(val.data.long)
                    }
                    this.setMarker(location);
                }
            }
        },
        village(val){
            if (val.data) {
                if (val.data.lat && val.data.long) {
                    let location = {
                        lat: parseFloat(val.data.lat),
                        lng: parseFloat(val.data.long)
                    }
                    this.setMarker(location);
                }
            }
        },
        category(val){
            this.typePropertyData = [];
            this.forms.type = null;
            let getPropertyType = this.typePropertyAll;
            this.typePropertyData = _.filter(getPropertyType, property => property.category_id == val.value);
        },
        type(val){
            this.sizingToggle(val || null);
        },
    },
    created(){
        this.transactionData = getListingTransaction();
        this.ownershipsData = getOwnerships();
        this.categoryData = getCategoryData();
        this.listingByData = getListingBy();
        this.typePropertyAll = this.getPropertyDataAll();
    },
    mounted(){
        this.sizing = this.defaultSizing()
        this.provinceData = this.getProvince();
        this.user_token = this.$store.state.authToken;
    },
    methods: {
        updateImage(preview_images, files, count){
            this.preview_images = preview_images;
            this.forms.images = files;
            if (count < 2) {
                this.iserrorImage = true;
            }else if (count > 7) {
                this.iserrorImage = true;
            }else{
                this.iserrorImage = false;
            }
        },
        onSubmit () {
            this.loading = true;
            if (this.formIndex === 4) {
                this.tosmodal = true;
            }else if(this.formIndex === 3){
                if (this.iserrorImage) {
                    this.loading = false;
                    return false;
                } else {
                    this.formIndex++;
                }
            }else{
                this.formIndex++;
            }

            this.loading = false;
        },
        sendData(){
            this.tosmodal =false;
            this.loading = true;
            const data = {
                property_name: this.forms.property_name,
                description: this.forms.description,
                province: this.forms.province.value,
                city: this.forms.city.value,
                district: this.forms.district ? this.forms.district.value : '',
                village: this.forms.village ? this.forms.village.value : '',
                listing_by: this.forms.listing_by.value,
                prefered_address: this.forms.prefered_address,
                transaction_type: this.forms.transaction_type.value,
                category: this.forms.category.value,
                type: this.forms.type.value,
                ownership: this.forms.ownership.value,
                latitude: this.forms.latitude,
                longitude: this.forms.longitude,
                address: this.forms.address,
                price: parseInt(this.forms.price || 0),
                building_size: parseInt(this.forms.building_size || 0),
                land_size: parseInt(this.forms.land_size || 0),
                bedroom: parseInt(this.forms.bedroom || 0),
                bathroom: parseInt(this.forms.bathroom || 0),
                carpark: parseInt(this.forms.carpark || 0),
                room: parseInt(this.forms.room || 0),
                images: this.forms.images
            }
            let formData = new FormData();

            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const tempData = data[key];
                    formData.append(key, tempData);
                }
            }

            this.forms.images.forEach((image, index) => {
                formData.append(`image[${index}]`, image);
            });

            formData.append('images', this.forms.images);
            const baseUrl = process.env.MIX_BASE_URL_API || "https://closebuyapi.democube.id/api";

                const insertListing = axios.create({
                    headers: {
                        // 'Authorization' : `Bearer ${this.token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                });

                insertListing.post('/listing', formData).then(response => {
                    if (response.data) {
                        if (response.data.success) {
                            this.loading = false;
                            Swal.fire({
                                title: 'Berhasil Menambahkan Listing',
                                html: 'Terima kasih telah mengajukan tayangan listing properti Anda, pantau status melalui <a href="/user/listing">My List</a>',
                                icon: 'success'
                            });
                            window.setTimeout(function(){
                                window.location = "/user/listing#";
                            }, 2000);
                        }else{
                            this.loading = false;
                            alert('Terjadi kesalahan saat membuat permintaan anda!');
                        }
                    }
                }).catch(failed => {
                    this.loading = false;
                    alert('Terjadi kesalahan saat membuat permintaan anda!');
                });
        },
        next(){
            console.log(this.forms);
        },
        prev(){
            if (this.formIndex < 2) {
                alert('Anda tidak dapat kembali di step ini');
                return;
            }
            this.formIndex--;
        },
        getProvince: function() {
            let provinceData = [];
            getAllProvince().then(response => {
                let resultProvince = response.data;
                resultProvince.forEach(element => {
                    let tmp = {
                        value: element.id,
                        text: element.name,
                        data: element.meta
                    };
                    provinceData.push(tmp);
                });
            });
            return provinceData;
        },
        provinceSelected(info){
            let cityData = [];
            getCityByProvince(info.value).then(response => {
                let resultCity = response.data;
                resultCity.forEach(element => {
                    let tmp = {
                        value: element.id,
                        text: element.name,
                        data: element.meta
                    };
                    cityData.push(tmp);
                });
            });
            this.cityData = cityData;
            return cityData;
        },
        citySelected(info){
            let districtData = [];
            getDistrictByCity(info.value).then(response => {
                let resultDistrict = response.data;
                resultDistrict.forEach(element => {
                    let tmp = {
                        value: element.id,
                        text: element.name,
                        data: element.meta
                    };
                    districtData.push(tmp);
                });
            });
            this.districtData = districtData;
            return districtData;
        },
        districtSelected(info){
            let villageData = [];
            getVillageByDistrict(info.value).then(response => {
                let resultVillage = response.data;
                resultVillage.forEach(element => {
                    let tmp = {
                        value: element.id,
                        text: element.name,
                        data: element.meta
                    };
                    villageData.push(tmp);
                });
            });
            this.villageData = villageData;
            return villageData;
        },
        sizingToggle(property){
            this.sizing = this.defaultSizing();
            this.resetSizing();
            let tmp = this.sizing
            for(let i = 0; i < property.featured_value.length; i++){
                let cur_value = property.featured_value[i]
                for (const key in tmp) {
                    if (Object.hasOwnProperty.call(tmp, key)) {
                        if(cur_value == key){
                            tmp[key] = 1
                        }
                    }
                }
            }
            return;
        },
        getPropertyDataAll(){
            let propertyData = [];
            getTypesProperty().then(response => {
                let results = response.data;
                results.forEach(element => {
                    let tmp = element;
                    propertyData.push(tmp);
                });
            });
            return propertyData;
        },
        resetSizing(){
            this.forms.building_size = null;
            this.forms.land_size = null;
            this.forms.bedroom = null;
            this.forms.bathroom = null;
            this.forms.carpark = null;
            this.forms.room = null;
        },

        defaultSizing(){
            return {
                building_size: 0,
                land_size: 0,
                bedroom: 0,
                bathroom: 0,
                carpark: 0,
                room: 0
            }
        },

        setPlace(place) {
            console.log(place);
            this.map.place = place;
            if (this.map.place) {
                let location = {
                    lat: this.map.place.geometry.location.lat(),
                    lng: this.map.place.geometry.location.lng()
                }
                this.setMarker(location)
            }
        },
        mark(event) {
            let location = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            }
            this.setMarker(location);
        },
        setMarker(location){
            let geocoder = new google.maps.Geocoder();

            geocoder
                .geocode({ location: location }).then((response) => {
                    if (response.results[0]) {
                        this.map.center = location;
                        this.forms.address = response.results[0].formatted_address
                    } else {
                        window.alert("No results found");
                    }
                })
                .catch((e) => window.alert("Geocoder failed due to: " + e));
            this.map.markers = [];
            this.forms.latitude = location.lat;
            this.forms.longitude = location.lng;
            this.map.markers.push({
                position: {
                    lat: location.lat,
                    lng: location.lng,
                }
            });
            this.map.center = location;
            this.map.place = null;
        }
    }
}

</script>


<style>

</style>
