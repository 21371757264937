var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listing-map-wrapper show"},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"options":{
            mapTypeControl: false,
            streetViewControl: false,
            disableDefaultUI: true,
            mapTypeId: 'roadmap',
            styles: _vm.mapStyle,
            gestureHandling: _vm.gestureHandling,
            minZoom: 12
        },"center":_vm.center,"zoom":_vm.zoom},on:{"idle":_vm.mapEvent}},[_c('cluster',{ref:"cluster",attrs:{"zoomOnClick":true}},_vm._l((_vm.dataListing),function(marker,index){return _c('gmap-custom-marker',{key:index,attrs:{"zIndex":50 + index,"marker":{
                    lat: parseFloat(marker.Latitude),
                    lng: parseFloat(marker.Longitude)
                }}},[_c('mark-style',{ref:"marker",refInFor:true,attrs:{"listing":marker}})],1)}),1),_vm._v(" "),_vm._l((_vm.singleMarker),function(marker,index){return _c('gmap-custom-marker',{key:index,attrs:{"zIndex":999999999,"marker":{
                lat: parseFloat(marker.Latitude),
                lng: parseFloat(marker.Longitude)
            }}},[_c('mark-style',{ref:"singleMarker",refInFor:true,attrs:{"listing":marker}})],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }