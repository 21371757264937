<template>
    <content-placeholders>
        <content-placeholders-img />
        <content-placeholders-heading />
    </content-placeholders>
</template>

<script>
export default {
    props: ["total"],
    data() {
        return {
            default: {
                total: 1,
            },
        };
    },
    computed: {
        totalItems() {
            return this.total || this.default.total;
        },
    },
};
</script>
