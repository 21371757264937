<template>
    <div class="card-props">
        <div @click="redirectMe(popular.Name, popular.PropertyID)">
            <div class="image-wrap relative">
                <img
                    @error="replaceImg"
                    :src="
                        loadImage(popular.photo, popular.PropertyID).replaceAll(
                            ' ',
                            '%20'
                        )
                    "
                    alt=""
                    class="h-full object-cover object-center w-full"
                />
            </div>
        </div>

        <div class="information-wrap w-full p-2 relative">
            <p
                @click="redirectMe(popular.Name, popular.PropertyID)"
                class="prop-name-lg"
                :title="popular.MetaTitle"
                v-text="popular.MetaTitle"
            ></p>
            <p v-text="popular.TypeName + ' ' + popular.TTName"></p>
            <div class="flex w-full justify-between mb-2">
                <span
                    class="mb-1 font-semibold text-sm"
                    v-text="convCur(Math.round(popular.AskingPrice))"
                ></span>
            </div>
            <div class="h-6">
                <p class="text-xs font-thin m-0" v-html="popular.sizing"></p>
            </div>
            <div>
                <CardFive
                    :bedroom="popular.Bedroom"
                    :bathroom="popular.Bathroom"
                    :ownership="popular.OwnershipName"
                    :category="popular.CategoryName"
                />
            </div>

            <div>
                <span
                    v-if="token"
                    @click="toggleFavorite(IsFavourite)"
                    class="icon-fav"
                    v-bind:class="{ 'is-fav': IsFavourite }"
                ></span>
                <span
                    v-else
                    @click="sudoLogin"
                    class="icon-fav"
                    v-bind:class="{ 'is-fav': IsFavourite }"
                ></span>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import CardFive from "../Utils/CardInformationFive.vue";

export default {
    props: [
        "popular",
        // 'token'
    ],
    data() {
        return {
            IsFavourite: 0,
        };
    },
    computed: {
        token() {
            return this.$userToken;
        },
    },
    mounted() {
        this.IsFavourite = this.popular.IsFavourite || 0;
    },
    components: {
        CardFive,
    },
    methods: {
        toggleFavorite(favorite) {
            if (favorite == 0) {
                // let tempFav = this.updateFavorite();
                this.updateFavorite();
            } else {
                Swal.fire({
                    text:
                        "Apakah Anda ingin menghapus listing properti ini dari daftar favorit Anda?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#006400",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ya",
                    cancelButtonText: "Tidak",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.updateFavorite();
                    }
                });
            }
        },
        loadImage: function (photo, id) {
            let path = photo[0]["URL"] || photo[0]["NewURL"];
            let filename = path.replace(/^.*[\\\/]/, "");
            return `/storage/listing/${filename}`;
        },
        updateFavorite() {
            console.log(Cookies.get("agreefav"));
            axios
                .post("/api/favour", {
                    id: this.popular.PropertyID,
                    _token: this.token,
                })
                .then((response) => {
                    this.IsFavourite = response.data.IsFavourite;
                    if (response.data.IsFavourite) {
                        if (Cookies.get("agreefav") == 1) {
                            console.log(response.data.message);
                        } else {
                            this.notification();
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        notification: async () => {
            const { value: accept } = await Swal.fire({
                html:
                    'Anda telah berhasil menyimpan listing properti ini sebagai Favorit Anda. Cek List Favorit Listing Anda <a href="/user/listing"> di sini </a>',
                input: "checkbox",
                inputValue: 1,
                inputPlaceholder: "Jangan tampilkan lagi, sampai sesi berikutnya",
                confirmButtonText: 'Continue <i class="fa fa-arrow-right"></i>',
                inputValidator: (result) => {
                    return !result && "You need to agree with T&C";
                },
            });

            if (accept) {
                Cookies.set("agreefav", 1, { expires: 7 });
            }
        },
        convCur: function (number) {
            const RP = (value) =>
                currency(value, {
                    symbol: "Rp. ",
                    precision: 0,
                    decimal: ",",
                    seperator: ".",
                });
            return RP(number).format();
        },
        redirectMe: function (name, prop) {
            window.open(`/listing/view/${slugify(name)}/${prop}`);
        },
        replaceImg: function (e) {
            e.target.src = "/img/plce/plce1.jpg";
        },
        sudoLogin() {
            loginModal("#login-modal");
        },
    },
};
</script>

<style></style>
