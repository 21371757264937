import Swiper, {
    Pagination,
    EffectFade,
    Navigation,
    EffectFlip,
    Autoplay,
    Lazy,
    Controller,
    Thumbs
} from "swiper/core";

Swiper.use([
    Navigation,
    Pagination,
    Lazy,
    EffectFade,
    EffectFlip,
    Autoplay,
    Controller,
    Thumbs
]);

import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import { loginModal } from './plugins/modal';
import tippy from "tippy.js";
import { Fancybox } from "@fancyapps/ui";
import axios from "axios";
import slugify from "slugify";
import AuthRepository from "./api/auth";
import { storeAnalytic } from "./api/analytics";

window.AuthRepository = AuthRepository;
window.storeAnalytic = storeAnalytic;

window.passwordValidate = require('password-validator');
window.loginModal = loginModal;

window.Swiper = Swiper;
window.Swal = Swal;
window.Cookies = Cookies;
window.Fancybox = Fancybox;
require("./plugins/sweetalertManager");
// require("./plugins/createListingFormManagement");
// require("./plugins/createWantedFormManagement");

document.addEventListener("DOMContentLoaded", function(event) {
    if(document.querySelector(".loading-map-section")){
        document.querySelector(".loading-map-section").classList.remove("show");
    }

    if (document.querySelectorAll(".form-helper").length > 0) {
        document.querySelectorAll(".form-helper").forEach(elem => {
            tippy(elem, {
                content: elem.getAttribute('data-tippy-content'),
                placement: "auto",
            });

        });
    }

    if (document.querySelectorAll('[data-tippy]').length > 0) {
        document.querySelectorAll('[data-tippy]').forEach(elem => {
            tippy(elem, {
                content: elem.getAttribute('data-tippy-content'),
                placement: "auto",
                trigger: 'mouseenter click',
            });
        });
    }
});

export function dSel(params) {
    return document.getElementById(params);
}

function cookieLocationSetter(lat, lng) {
    Cookies.set('lat', lat, { expires: 7 })
    Cookies.set('lng', lng, { expires: 7 })
}

function userCookieLocation(userlocation = null) {
    if (userlocation) {
        Cookies.set('user_location', userlocation, {expires: 1})
    }else{
        Cookies.remove('user_location')
    }
}

function getUserCookieLocation() {
    return Cookies.get('user_location') || null;
}

function toggleListingMap(element) {
    let attribute = element.getAttribute("data-show");
    if (attribute == "map") {
        element.setAttribute("data-show", "list");
        document.querySelector(".listing-card-wrapper").classList.add("show");
        document.querySelector(".listing-map-wrapper").classList.remove("show");
        document.querySelector("button.map-toogler").innerHTML = 'Map <i class="fas fa-map ml-1"></i>';
    } else if (attribute == "list") {
        element.setAttribute("data-show", "map");
        document
        .querySelector(".listing-card-wrapper")
        .classList.remove("show");
        document.querySelector(".listing-map-wrapper").classList.add("show");
        document.querySelector("button.map-toogler").innerHTML = 'List <i class="fas fa-table ml-1"></i>';
    }
}

function toggleWantedMap(element) {
    let attribute = element.getAttribute("data-show");
    if (attribute == "map") {
        element.setAttribute("data-show", "list");
        document.querySelector(".wanted-card-wrapper").classList.add("show");
        document.querySelector(".wanted-map-wrapper").classList.remove("show");
        document.querySelector("button.map-toogler").innerHTML =
            'Map <i class="fas fa-map ml-1"></i>';
    } else if (attribute == "list") {
        element.setAttribute("data-show", "map");
        document
            .querySelector(".wanted-card-wrapper")
            .classList.remove("show");
        document.querySelector(".wanted-map-wrapper").classList.add("show");
        document.querySelector("button.map-toogler").innerHTML =
            'List <i class="fas fa-table ml-1"></i>';
    }
}

function toggleProjectMap(element) {
    let attribute = element.getAttribute("data-show");
    if (attribute == "map") {
        element.setAttribute("data-show", "list");
        document.querySelector(".project-card-wrapper").classList.add("show");
        document.querySelector(".project-map-wrapper").classList.remove("show");
        document.querySelector("button.map-toogler").innerHTML =
            'Map <i class="fas fa-map ml-1"></i>';
    } else if (attribute == "list") {
        element.setAttribute("data-show", "map");
        document.querySelector(".project-card-wrapper").classList.remove("show");
        document.querySelector(".project-map-wrapper").classList.add("show");
        document.querySelector("button.map-toogler").innerHTML =
            'List <i class="fas fa-table ml-1"></i>';
    }
}

function cbSlugUrl(url) {
    return slugify(url, {
        replacement: "-",
        lower: true,
        remove: /[*+~.,()'"/\\!:@]/g
    });
}


window.slugify = cbSlugUrl;
window.toggleListingMap = toggleListingMap;
window.toggleWantedMap = toggleWantedMap;
window.toggleProjectMap = toggleProjectMap;
window.cookieLocationSetter = cookieLocationSetter;
window.userCookieLocation = userCookieLocation;
window.getUserCookieLocation = getUserCookieLocation;



