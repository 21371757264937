<template>
    <section>
        <h5 class="section-title title-green">{{sectionTitle}}</h5>
        <div class="popular-list relative">
            <swiper ref="populasListsSwiper" :options="swiperOption">
                <swiper-slide v-for="project in projects" :key="project.PrimaryProjectID" class="group">
                    <div class="card-props">
                        <div @click="redirectMe(project.Name, project.PrimaryProjectID)">
                            <div class="image-wrap relative">
                                <img @error="replaceImg" :src="loadImage(project.photos, project.PrimaryProjectID).replaceAll(' ', '%20')" alt="" class="h-full object-cover object-center w-full">
                            </div>
                        </div>

                        <div class="information-wrap w-full p-2 relative">
                            <p @click="redirectMe(project.Name, project.PrimaryProjectID)" class="prop-name-lg" :title="project.Name" v-text="project.Name"></p>
                            <p v-text="project.type.label+' '+project.transaction_type.label"></p>
                            <div class="flex w-full justify-between mb-2">
                                <span class="mb-1 tracking-tight font-semibold" v-text="convCur(Math.round(project.AskingPrice))"></span>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="swiper-button-prev outside" slot="button-prev">
                <i class="fas fa-chevron-left"></i>
            </div>
            <div class="swiper-button-next outside" slot="button-next">
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import CardFive from "../../../Utils/CardInformationFive.vue";

export default {
    props: ['projects', 'strings', 'sectionTitle'],
    data() {
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 10,
                pagination: false,
                navigation: {
                    nextEl: '.popular-list .swiper-button-next',
                    prevEl: '.popular-list .swiper-button-prev'
                },
                breakpoints: {
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    }
                }
            }
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        CardFive
    },
    directives: {
        swiper: directive
    },
    methods: {
        loadImage : function (photo, id) {
            if (photo.length > 0) {
                return photo[0].URL;
            }else{
                return '/img/plce/plce1.jpg';
            }
        },
        convCur : function (number) {
            const RP = value => currency(value, {symbol : 'Rp. ', precision: 0, decimal: ',', seperator: '.'});
            return RP(number).format();
        },
        redirectMe : function (name, prop) {
            window.open(`/project/view/${slugify(name)}/${prop}`)
        },
        favourite: function (data) {
            let fav_class = 'icon-fav';
            if ("IsFavourite" in data) {
                if (data.IsFavourite) {
                    fav_class = "icon-fav is-fav";
                }
            }

            return fav_class;
        },
        replaceImg: function (e) {
            e.target.src = '/img/plce/plce1.jpg'
        }
    }

}
</script>
