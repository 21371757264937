<template>
    <div class="listing-card-wrapper flex-grow h-full">
        <div class="filter-wrapper">
            <div class="category-button">
                <div
                    v-for="(category, index) in categories"
                    @click="toggleCategory(index, category.isOn)"
                    :key="index"
                    :class="`button-filter ${category.classCode} ${ category.isOn ? 'active' : ''}`">
                    {{category.text}}
                </div>

                <!-- @if (array_key_exists('login',$auth_status) && $auth_status['login'])
                    <a target="_blank" href="{{ route('listing.create') }}" class="flex items-center bg-white py-1 px-4 border-green border text-green text-xs"><i class="fas fa-plus mr-1"></i>Daftarkan Listing</a>
                @else
                    <button class="block bg-white py-1 px-4 border-green border text-green text-xs open-modal" data-modal="#login-modal"><i class="fas fa-plus mr-1"></i>Daftarkan Listing</button>
                @endif -->
            </div>

            <div class="flex md:flex-row md:justify-between md:items-center flex-col-reverse">
                <div class="text-xs  mt-2 md:mt-0">Menampilkan <span id="result" class="font-semibold">{{ dataListing.length }}</span> properti di area peta</div>

                <div>
                    <div class="relative">
                        <button type="button" @click="showSortBox = !showSortBox" class="button-sort">opsi pilihan <span class="font-semibold">{{ selectedSort().text }}</span> <i class="fas fa-chevron-down text-green ml-2"></i></button>
                        <!-- <button onclick="toggleFilter(this)" type="button" class="button-filter-adv">Filter</button> -->
                        <div class="sort-box" v-bind:class="{show : showSortBox}">
                            <div class="sort-direction-box">
                                <div @click="selectSortDir(index, sort.selected)" v-for="(sort, index) in sort_directions" :key="index" :class="`sort-direction-item ${sort.selected ? 'active' : '' }`">
                                    {{ sort.text }}
                                </div>
                            </div>

                            <div class="sort-selection">
                                <div @click="selectSortItem(index, sort.selected)" v-for="(sort, index) in sorts" :key="index" :class="`sort-selection-item ${ sort.selected ? 'active' : '' }`">{{ sort.text }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="px-2 pt-2 pb-4 relative h-full">
            <div class="card-wrapper" id="card-list">
                <card @mouseleave.native="unselectCard" @mouseover.native="selectCard(popular)" :popular="popular" v-for="popular in dataListing" :key="popular.PropertyID"/>
            </div>
        </div>
    </div>
</template>

<script>
import Card from './Utils/Card.vue';
export default {
    props: {
        dataListing: {
            type: Array,
            required: true
        },
        map: {
            type: Object,
            required: false
        }
    },
    data(){
        return {
            showSortBox : false,
            categories: [
                {
                    isOn: true,
                    text: 'Residensial',
                    value: 1,
                    classCode: 'green-light'
                },
                {
                    isOn: true,
                    text: 'Komersial',
                    value: 2,
                    classCode: 'red'
                },
                {
                    isOn: true,
                    text: 'Industrial',
                    value: 3,
                    classCode: 'orange'
                }
            ],
            sorts: [
                {
                    "value": "ViewCount",
                    "text": "Paling Populer",
                    "selected": true
                }, {
                    "value": "PriceAmount",
                    "text": "Harga",
                    "selected": false
                }, {
                    "value": "LandSize",
                    "text": "Ukuran Tanah",
                    "selected": false
                }, {
                    "value": "BuildingSize",
                    "text": "Ukuran Bangunan",
                    "selected": false
                }, {
                    "value": "ModifiedDate",
                    "text": "Terbaru",
                    "selected": false
                },
            ],
            sort_directions:  [
                {'selected' : true,'value' : 'ASC', 'text' : 'Menaik'},
                {'selected' : false,'value' : 'DESC', 'text' : 'Menurun'}
            ]
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.$emit('card_loaded', this.getDataFilter());
        })
    },
    methods: {
        selectCard(card){
            this.$emit('card_hover', card);
        },
        unselectCard(){
            this.$emit('card_unhover');
        },
        toggleCategory(a, b){
            this.categories[a].isOn = !b;
            this.$emit('filter_changed', this.getDataFilter());
        },
        selectedSort(){
            let data = null;
            this.sorts.forEach(sort => {
                if (sort.selected) {
                    data = sort;
                }
            });

            return data;
        },
        selectSortDir(a, b){
            this.sort_directions.forEach(sort => {
                sort.selected = false;
            });
            this.sort_directions[a].selected = !b;
            this.$emit('filter_changed', this.getDataFilter());

        },
        selectSortItem(a, b){
            this.sorts.forEach(sort => {
                sort.selected = false;
            });
            this.sorts[a].selected = !b;
            this.$emit('filter_changed', this.getDataFilter());

        },
        getDataFilter(){
            let obj = {
                category: [],
                sort: null,
                sort_direction: null
            };
            this.categories.forEach(category => {
                if (category.isOn) {
                    obj.category.push(category);
                }
            });
            this.sort_directions.forEach(sort => {
                if (sort.selected) {
                    obj.sort = sort;
                }
            });
            this.sorts.forEach(sort => {
                if (sort.selected) {
                    obj.sort_direction = sort;
                }
            });

            return obj;
        }
    },
    components: {
        Card
    }
}
</script>

<style>

</style>
