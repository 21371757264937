<template>
    <section v-show="units.length > 0" v-cloak>
        <p class="section-title text-lg">Tipe dipasarkan</p>
        <div class="relative">
            <swiper ref="unitSwiper" :options="swiperOption">
                <swiper-slide v-for="unit in units" :key="unit.id">
                    <div class="card-props">
                        <div data-fancybox="units" :data-src="imgSource(unit.FloorPlan)">
                            <div class="image-wrap-unit relative bg-gray-600" :style="{ backgroundImage: loadImage(unit.FloorPlan) }">
                                <span class="absolute bottom-0 right-0 mr-2 mb-1">
                                    <i class="fas fa-plus-square text-xl text-green opacity-100 scale-100 hover:scale-125 transform transition-transform duration-200"></i>
                                </span>
                            </div>
                        </div>

                        <div class="information-wrap w-full p-2 relative">
                            <p class="prop-name-lg" :title="unit.UnitName" v-text="unit.UnitName"></p>
                            <p v-text="unit.TypeName+' '+unit.TTName"></p>
                            <div class="flex w-full justify-between mb-2">
                                <span class="mb-1 tracking-tight font-semibold" v-text="convCur(Math.round(unit.MinPrice))"></span>
                            </div>
                            <div class="unit-property-size" v-html="unit.Size"></div>
                            <div>
                                <CardFive :bedroom="unit.Bedroom" :bathroom="unit.Bathroom" :ownership="unit.Certificate" :category="unit.CategoryName"/>
                            </div>

                            <!-- <div :class="favourite(popular)"></div> -->
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="swiper-button-prev outside" slot="button-prev">
                <i class="fas fa-chevron-left"></i>
            </div>
            <div class="swiper-button-next outside" slot="button-next">
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
    </section>

</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import CardFive from "../Utils/CardInformationFive.vue";

export default {
    props: ['units'],
    data(){
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 10,
                pagination: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    }
                }
            }
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        CardFive
    },
    directives: {
        swiper: directive
    },
    mounted(){
        Fancybox.bind("[data-fancybox=units]")
    },
    methods: {
        loadImage: function (name = NULL) {
            if (name) {
                return `url('${this.imgSource(name)}')`;
            }
            return `url('/img/plce/plce1.jpg')`;
        },
        imgSource: function (name) {
            return `${name}`;
        },
        convCur : function (number) {
            if (number) {
                const RP = value => currency(value, {symbol : 'Rp. ', precision: 0, decimal: ',', seperator: '.'});
                return `Mulai dari ${RP(number).format()}`;
            }else{
                return 'Kontak Kami'
            }

        }
    }
}
</script>

<style>

</style>
