const { default: axios } = require("axios");

const baseUrl =
    process.env.MIX_CMS_BASE_URL_API;

const propertyAxios = axios.create({
    baseURL: baseUrl
});

export function getAdds() {
    return propertyAxios.get("/cb_resources/adds");
}
