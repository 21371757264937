<template>
    <section class="section-container">
        <h5 class="section-title title-green">Proyek Baru Rekomendasi CloseBuy</h5>
        <div v-if="isLoading" class="grid grid-cols-4 gap-4">
            <loading-card v-for="i in 4" :key="i"></loading-card>
        </div>
        <div v-else-if="!isLoading && items" class="project-list relative">
            <swiper v-if="items" ref="projectRecommendedSlider" :options="swiperOption">
                <swiper-slide
                    v-for="projectRec in items"
                    :key="projectRec.PrimaryProjectID"
                    class="group"
                >
                    <div
                        class="card-props"
                        @click="redirectMe(projectRec.Name, projectRec.PrimaryProjectID)"
                    >
                        <div class="image-wrap relative">
                            <img
                                :src="loadImage(projectRec.photos)"
                                :alt="projectRec.Name"
                                @error="fallbackPhoto"
                                class="object-cover object-center h-full w-full"
                            />
                        </div>

                        <div class="information-wrap w-full p-2">
                            <p
                                class="prop-name-lg"
                                :title="projectRec.Name"
                                v-text="projectRec.Name"
                            ></p>
                            <div
                                class="text-black project-description"
                                v-if="projectRec.Tagline"
                                v-text="projectRec.Tagline"
                            ></div>
                            <div
                                class="text-black project-description"
                                v-else
                                v-html="projectRec.Description"
                            ></div>

                            <div class="flex justify-between items-end">
                                <div class="text-left">
                                    <p class="text-sm">Mulai dari</p>
                                    <p class="text-xs">Rp. {{ projectRec.price_abbr }}</p>
                                </div>
                                <div>
                                    <span
                                        class="label"
                                        v-text="projectRec.CategoryName"
                                        :class="loadClass(projectRec.CategoryName)"
                                    ></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="swiper-button-prev outside" slot="button-prev">
                <i class="fas fa-chevron-left"></i>
            </div>
            <div class="swiper-button-next outside" slot="button-next">
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
    props: [
        "strings",
        // "projectrecom"
    ],
    data() {
        return {
            isLoading: false,
            items: [],
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                pagination: false,
                preloadImages: true,
                lazyLoading: true,
                lazy: {
                    loadPrevNext: true,
                },
                navigation: {
                    nextEl: ".project-list .swiper-button-next",
                    prevEl: ".project-list .swiper-button-prev",
                },
                breakpoints: {
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                },
            },
        };
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive,
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        // fallbackPhoto(data, defaultImage = "/img/plce/plce1.jpg") {
        //     console.log(data);
        //     console.log(data.target.src);
        //     console.log(defaultImage);
        //     data.target.src = defaultImage;
        // },
        loadImage: function (photo, id) {
            return photo[0]["URL"];
        },
        loadClass: function (category) {
            if (category === "Residential") {
                return "bg-green-light";
            } else if (category === "Commercial") {
                return "bg-red";
            } else {
                return "bg-orange";
            }
        },
        convCur: function (number) {
            const RP = (value) =>
                currency(value, {
                    symbol: "Rp. ",
                    precision: 0,
                    decimal: ",",
                    seperator: ".",
                });
            return RP(number).format();
        },
        redirectMe: function (name, prop) {
            window.open(`/project/view/${slugify(name)}/${prop}`);
        },
        slugs: function (name) {
            slugify(name);
        },
        loadItems() {
            this.isLoading = true;
            this.getItems()
                .then((response) => {
                    this.items = response.data.data;
                })
                .then(() => {
                    this.isLoading = false;
                });
        },
        getItems() {
            let url = `/v1/primary_project/recomended`;
            return this.$httpApi
                .get(url)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
