<template>
    <popper
        trigger="clickToOpen"
        :options="{
            placement: 'top',
            gpuAcceleration: true,
            modifiers: { offset: { offset: '0,10px' } },
        }"
        :root-class="`property-${listing.PropertyID}`"
        :append-to-body="true"
        >
        <div class="popper" style="z-index: 99999999;">
            <div class="flex p-1">
                <div class="w-28">
                    <img :src="`https://qa.closebuy.asia/storage/listing/${listing.photo[0].URL}`" alt="">
                </div>
                <div class="w-40">
                    {{ listing.Name }}
                </div>
            </div>
        </div>
        <div
            :class="`label-map ${setClassMarker(listing)} ${isHovering ? 'visit' : ''}`"
            slot="reference"
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            >
            {{ listing.price_abbr }}
        </div>
    </popper>
</template>

<script>
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
    props: [
        'listing'
    ],
    components: {
        'popper': Popper
    },
    data(){
        return{
            isHovering: false,
            classes: null
        }
    },
    mounted(){
        this.classes = this.$refs.marker.classList.toString();
    },
    methods: {
        setClassMarker(data){
            let className= '';
            if (data.EngTypeName == 'House') {
                className = 'label-map-green house';
            } else if (data.EngTypeName == 'Apartment') {
                className = 'label-map-green apart';
            } else if (data.EngTypeName == 'Land Plot' && data.CategoryName == 'Residential') {
                className = 'label-map-green land';
            } else if (data.EngTypeName == 'Villa') {
                className = 'label-map-green villa'; // 4
            } else if (data.EngTypeName == 'Shophouse') {
                className = 'label-map-red shop';
            } else if (data.EngTypeName == 'Stall') {
                className = 'label-map-red stall';
            } else if (data.EngTypeName == 'Land Plot' && data.CategoryName == 'Commercial') {
                className = 'label-map-red land';
            } else if (data.EngTypeName == 'Office') {
                className = 'label-map-red office';
            } else if (data.EngTypeName == 'Hotel') {
                className = 'label-map-red hotel';
            } else if (data.EngTypeName == 'Stand-alone Building') {
                className = 'label-map-red std'; // 2
            } else if (data.EngTypeName == 'Factory') {
                className = 'label-map-orange factory';
            } else if (data.EngTypeName == 'Warehouse') {
                className = 'label-map-orange warehouse';
            } else if (data.EngTypeName == 'Land Plot' && data.CategoryName == 'Industrial') {
                className = 'label-map-orange land';
            } else if (data.EngTypeName == 'Others') {
                className = 'label-map-orange other';
            } else {
                className = 'label-map-orange other'; // 3
            }
            return className;
        }
    }
}
</script>
