<template>
    <div class="search-tab-wrapper">
        <div class="tab-menu">
            <div class="menu-item" v-for="tab in tabs" :key="tab.name" :class="{ 'active': tab.isActive }" @click="selectTab(tab)">{{ tab.name }}</div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabs: []

        }
    },
    created() {
        this.tabs = this.$children;
    },
    methods: {
        selectTab: function(selectedTab) {
            this.tabs.forEach(tab => {
                tab.isActive = (tab.name == selectedTab.name);
            });
        }
    }
}
</script>

<style>

</style>
