<template>
    <div class="w-full mt-2 h-full">
        <div
            class="card w-full mb-8 sticky top-16 bg-white z-10 shadow-lg"
            v-if="isCardShow"
        >
            <div class="card-header py-4 bg-green border border-green rounded-t-sm">
                <p
                    class="text-base tracking-tight font-medium text-center text-white"
                    v-if="property.PropertyID || property.WantedID"
                >
                    {{ property.TypeName }} {{ property.TTName }}
                </p>
                <p
                    class="text-base tracking-tight font-medium text-center text-white"
                    v-else
                >
                    {{ property.Name }}
                </p>
            </div>

            <div
                class="card-body grid grid-cols-1 gap-y-4 py-4 border-2 border-green rounded-b-sm"
            >
                <a
                    @click.prevent="handleClick(property)"
                    class="w-3/4 block p-1 border-2 mx-auto rounded text-center text-base tracking-tight border-green text-green cursor-pointer"
                    title="CloseBuy Advisor"
                    ><i class="fab fa-whatsapp font-medium mr-2"></i> CloseBuy Advisor</a
                >
                <div
                    v-if="authStatus.login === true"
                    class="w-3/4 block p-1 border-2 mx-auto rounded text-center text-base tracking-tight cursor-pointer border-green text-green"
                    @click="favorite"
                >
                    <i class="far fa-star font-medium mr-2"></i
                    ><span v-text="favText"></span>
                </div>
                <div
                    v-else
                    class="w-3/4 block p-1 border-2 mx-auto rounded text-center text-base tracking-tight cursor-pointer border-green text-green open-modal"
                    data-modal="#login-modal"
                >
                    <i class="far fa-star font-medium mr-2"></i
                    ><span v-text="favText"></span>
                </div>
                <hr />
                <p class="tracking-tight font-medium text-center">
                    Butuh bantuan?
                    <a :href="ctaContact" target="blank" class="text-green font-semibold"
                        >Hubungi Kami</a
                    >
                    sekarang
                </p>
            </div>
        </div>

        <div v-if="loadServices">
            <div v-for="s in services" :key="s.id">
                <AddBox :service="s" v-if="s.advertises.length > 0" />
            </div>
        </div>
        <div v-else>
            <div class="mb-8" v-for="i in 6" :key="i">
                <loading-card></loading-card>
            </div>
        </div>
    </div>
</template>

<script>
import AddBox from "./AddsBox.vue";
import { getAdds } from "../../../api/adds";
import axios from "axios";
import moment from "moment";
import { storeAnalytic } from "../../../api/analytics";

export default {
    props: {
        property: {
            type: Object,
        },
        route: {
            type: String,
        },
        authStatus: {
            type: Object,
        },
        templateMessage: {
            type: String,
        },
    },
    data() {
        return {
            IsFavourite: 0,
            services: null,
            loadServices: false,
            isCardShow: false,
            user_token: null,
        };
    },
    components: {
        AddBox,
    },
    computed: {
        favText: function () {
            if (this.IsFavourite) {
                return "Di Favoritkan";
            }
            return "Tambah Sebagai Favorit";
        },
        whatsapp() {
            const message = encodeURIComponent(this.templateMessage);
            const waUrl = `https://wa.me/628119771073?text=${message}%0A%0A%5B${window.location.href}%5D`;
            return waUrl;
        },
        ctaContact() {
            let path = this.route.charAt(0).toUpperCase() + this.route.slice(1);
            const action = `https://wa.me/628119771076?text=Halo+CloseBuy%21+Saya+ingin+meminta+panduan+mengenai+menu+${path}&lang=id`;
            return action;
        },
        table() {
            return this.route;
        },
    },
    mounted() {
        let now = moment();
        if (this.property.StatusID == 2) {
            this.isCardShow = true;
            if (this.property.ExpiredDate) {
                let expiredDate = moment(this.property.ExpiredDate);
                if (now < expiredDate) {
                    this.isCardShow = true;
                } else {
                    this.isCardShow = false;
                }
            }
        } else if (this.property.WantedStatusID == 2) {
            let expiredDate = moment(this.property.ExpiredDate);
            this.isCardShow = true;
            if (now < expiredDate) {
                this.isCardShow = true;
            } else {
                this.isCardShow = false;
            }
        } else {
            this.isCardShow = false;
        }

        this.IsFavourite = this.property.IsFavourite;

        if (this.authStatus.hasOwnProperty("login")) {
            if (this.authStatus.login == true) {
                this.user_token = this.authStatus.token || null;
            }
        }

        this.getAds()
            .then((response) => {
                this.services = response.data;
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
                this.loadServices = true;
            });

        // getAdds()
        //     .then((response) => {
        //         // let data = response.data.splice(0, 1); //for development load 1 services
        //         let data = response.data;
        //         this.services = data;
        //         this.loadServices = true;
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    },
    methods: {
        favorite: function () {
            console.log(this.authStatus);
            axios
                .post(`/api/${this.route}/favourite`, {
                    id: this.getId(),
                    _token: this.authStatus.token,
                })
                .then((response) => {
                    console.log(response);
                    if (response.data.code == 200 && response.data.IsFavourite) {
                        this.IsFavourite = response.data.IsFavourite;
                    } else {
                        this.IsFavourite = response.data.IsFavourite;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getId: function () {
            if (this.route == "wanted") {
                return this.property.WantedID;
            } else if (this.route == "listing") {
                return this.property.PropertyID;
            } else if (this.route == "project") {
                return this.property.PrimaryProjectID;
            }
        },
        handleClick(property) {
            console.log(this);
            let data = {
                id: this.getId(),
                page: this.route,
                token: this.user_token,
            };
            window.open(this.whatsapp, "_blank");
            storeAnalytic("cca", data)
                .then(() => {
                    // window.open(this.whatsapp, "_blank");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getAds() {
            return this.$httpApi
                .get("/cbad/general")
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    console.log(error);
                    return error;
                });
        },
    },
};
</script>
