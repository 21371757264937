<template>
    <div class="mb-4">
        <div class="mb-4 md:w-full">
            <label for="email" class="block mb-1">Password Sekarang</label>
            <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
                <input
                    class="w-full border rounded p-2 outline-none focus:shadow-outline"
                    type="password"
                    v-model="current_password"
                    id="password"
                    placeholder="Password Sekarang"
                />
                <small class="error">{{ errors[0] }}</small>
            </ValidationProvider>
        </div>
        <div class="mb-6 md:w-full">
            <label for="password" class="block mb-1">Password Baru</label>
            <ValidationProvider name="Password Baru" rules="required" v-slot="{ errors }">
                <input
                    class="w-full border rounded p-2 outline-none focus:shadow-outline"
                    type="password"
                    v-model="new_password"
                    id="new_password"
                    placeholder="Password Baru"
                />
                <small class="error">{{ errors[0] }}</small>
            </ValidationProvider>
        </div>
        <div class="mb-6 md:w-full">
            <label for="password" class="block mb-1">Ulangi Password Baru</label>
            <input
                class="w-full border rounded p-2 outline-none focus:shadow-outline"
                type="password"
                v-model="confirm_password"
                id="confirm_password"
                placeholder="Ulangi Password Baru"
            />
        </div>
        <button class="btn btn-primary px-4 py-2" @click="submit">Ganti Password</button>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
    props: ["token"],
    data() {
        return {
            current_password: null,
            new_password: null,
            confirm_password: null,
        };
    },
    methods: {
        submit() {
            const baseUrl =
                process.env.MIX_BASE_URL_API || "https://closebuyapi.democube.id/api";

            const data = {
                OldPassword: this.current_password,
                NewPassword: this.new_password,
                NewPasswordConfirmation: this.confirm_password,
            };

            const changePassword = axios.create({
                baseURL: baseUrl,
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            });

            changePassword
                .post("/user/changePassword", data)
                .then((response) => {
                    let status = response.data.success || false;
                    if (status) {
                        Swal.fire({
                            title: "Berhasil!",
                            text: "Password berhasil diubah",
                            icon: "success",
                        });
                        window.setTimeout(function () {
                            window.location = "/user/profile#";
                        }, 1800);
                    } else {
                        this.$toast.open({
                            message: `${response.data.message}`,
                            type: "error",
                            position: "top",
                            queue: true,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    alert("Sistem galat");
                });
        },
    },
};
</script>

<style></style>
