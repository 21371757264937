<template>
    <nav data-nav-scroll class="navigation nav-home" id="nav-scroll">
        <div class="nav-wrapper">
            <a href="/" class="logo-wrapper ml-2">
                <img src="/img/logo_green.png" class="block" alt="CloseBuy" />
            </a>
            <ul class="menu-items">
                <li class="menu-item"><a href="/listing">Listing</a></li>
                <li class="menu-item"><a href="/wanted">Dicari</a></li>
                <li class="menu-item"><a href="/project">Proyek Baru</a></li>

                <li class="ml-2 menu-item">
                    <button
                        v-if="authStatus.login === true"
                        id="profile-menu"
                        class="text-green hover:text-green-fallback bg-white hover:bg-gray-200 p-2 text-lg"
                        aria-expanded="false"
                    >
                        <i class="fas fa-user-alt mx-auto"></i>
                    </button>
                    <button
                        v-else
                        class="btn btn-primary open-modal"
                        data-modal="#login-modal"
                        @click="sudoLogin()"
                    >
                        Login
                    </button>
                </li>
            </ul>

            <div class="p-1 overflow-hidden ml-auto bg-green block md:hidden mr-2">
                <button
                    type="button"
                    class="nav-toggler"
                    v-bind:class="{ show: isShow }"
                    @click="toggleNav"
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <!-- <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" class="svg-inline--fa fa-bars fa-w-14 fill-current default" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg> -->
                </button>
            </div>

            <Transition name="nav-slide">
                <ul class="menu-items-mobile" v-if="isShow">
                    <li class="menu-item">
                        <button
                            v-if="authStatus.login === true"
                            class="bg-white text-green p-2 text-lg"
                            :content="content"
                            v-tippy="options"
                        >
                            <i class="fas fa-user-alt mx-auto"></i>
                        </button>
                        <button
                            v-else
                            class="btn btn-light open-modal"
                            data-modal="#login-modal"
                            @click="sudoLogin()"
                        >
                            Login
                        </button>
                    </li>
                    <li class="menu-item"><a href="/listing">Listing</a></li>
                    <li class="menu-item"><a href="/wanted">Di Cari</a></li>
                    <li class="menu-item"><a href="/project">Proyek Baru</a></li>
                    <!-- <div class="nav-toggler">
                        <svg class="fill-current close" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="32px" height="32px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"/></svg>
                    </div> -->
                </ul>
            </Transition>
        </div>
        <div class="hidden" ref="profileContent">
            <div class="w-40">
                <a
                    href="/user/profile"
                    class="px-2 py-4 block text-base text-green hover:bg-green-fallback hover:text-white transition-colors duration-50"
                    >My Account</a
                >
                <a
                    href="/user/listing"
                    class="px-2 py-4 block text-base text-green hover:bg-green-fallback hover:text-white transition-colors duration-500"
                    >My List</a
                >
                <a
                    href="/auth/logout"
                    class="px-2 py-4 block text-base text-green hover:bg-green-fallback hover:text-white transition-colors duration-500"
                    >Logout</a
                >
            </div>
        </div>
    </nav>
</template>

<script>
import { navScrollAnimation } from "../../method";
export default {
    props: ["strings", "authStatus"],
    data() {
        return {
            isShow: false,
            options: null,
            content: null,
        };
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    mounted() {
        this.content = this.$refs.profileContent.innerHTML;
        if (this.authStatus.login) {
            this.$store.state.authToken = this.authStatus.token;
            this.options = {
                trigger: "click",
                theme: "profile-box",
                arrow: true,
                placement: "bottom",
                interactive: true,
                allowHTML: true,
                duration: [500, 200],
            };
        }
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll(event) {
            navScrollAnimation();
        },
        toggleNav() {
            this.isShow = !this.isShow;
        },
        sudoLogin() {
            loginModal("#login-modal");
        },
    },
};
</script>

<style></style>
