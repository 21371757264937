<template>
    <div class="card-props h-136px flex-col shadow border cursor-pointer p-3 justify-between" @click="redirectPage()">
        <div class="mb-2">
            <p class="prop-name-lg mb-1">{{title}}</p>
            <p class="text-sm h-14 overflow-hidden overflow-ellipsis ...">{{ address }}</p>
        </div>
        <div>
            <button class="px-2 py-1 text-center text-white border" :class="'bg-'+loadColor(category)">{{category}}</button>
        </div>
    </div>
</template>

<script>

export default {
    props: [
        'category',
        'transType',
        'address',
        'type',
        'wantedId'
    ],
    data(){
        return{
            title: '',
        }
    },
    created(){
        let title = 'Dicari '+ this.type + ' untuk di' + this.transType;
        title = _.startCase(_.toLower(title));
        this.title = title;
    },
    methods: {
        loadColor : function (category) {
            if (category === 'Residential') {
                return 'green-light';
            }else if(category === 'Commercial'){
                return 'red';
            }else{
                return 'orange';
            }
        },
        redirectPage: function (){
            window.open(`/wanted/view/${slugify(this.address)}/${this.wantedId}`)
        }
    }
}
</script>

<style>

</style>
