<template>
    <div class="flex flex-col space-y-3 w-420px">
        <card v-for="(notif, key ) in notification" :key="key" :message="notif"/>
    </div>
</template>

<script>
import Card from './Card.vue'
export default {
    props: [
        'notification'
    ],
    components: {
        Card
    },
    mounted(){
        console.log(this.notification);
    }
}
</script>

<style>

</style>
