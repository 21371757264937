<template>
    <div v-if="advertise.length > 0">
        <div class="mb-4" v-if="isSlide">
            <swiper
                :ref="'mySwiper' + service.id"
                :options="swiperOptions"
                :dir="isRtl ? `rtl` : ''"
                class="swiper slider-adds"
                @slideChangeTransitionEnd="reachEnd"
            >
                <swiper-slide v-for="item in advertise" :key="item.id">
                    <img
                        :src="item.image_location"
                        alt=""
                        class="w-full"
                        @click="clickAdds(item.url)"
                    />
                </swiper-slide>
                <div
                    :class="`swiper-pagination swiper-adds-pagination-${service.id}`"
                    slot="pagination"
                ></div>
            </swiper>
        </div>
        <div class="mb-4" v-else>
            <img
                :src="advertise[0].image_location"
                @click="clickAdds(advertise[0].url)"
                alt=""
                class="w-full"
            />
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    props: {
        service: {
            required: true,
            type: Object,
        },
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            isSlide: false,
            advertise: [],
            swiperOptions: null,
            isRtl: false,
        };
    },
    mounted() {
        this.isSlide = this.service.slide == 1 ? true : false;
        this.isRtl = this.service.rtl == 1 ? true : false;
        this.advertise = this.service.advertises;
        this.swiperOptions = {
            autoplay: {
                delay: this.service.speed,
                // disableOnInteraction: true,
                //waitForTransition: false,
                reverseDirection: false,
            },
            pagination: {
                el: `.swiper-adds-pagination-${this.service.id}`,
                clickable: true,
            },
            loop: false,
            freeMode: true,
            slidesPerView: 1,
        };
    },
    computed: {
        swiper() {
            return this.$refs[`mySwiper${this.service.id}`].$swiper;
        },
    },
    methods: {
        clickAdds(link = null) {
            if (link == null) {
                return "#";
            } else {
                window.open(`${link}`);
            }
        },
        reachEnd(swiper) {
            if (this.service.advertises.length == swiper.activeIndex) {
                swiper.params.autoplay.reverseDirection = true;
                this.swiper.update();
            } else if (swiper.activeIndex == 1) {
                swiper.params.autoplay.reverseDirection = false;
                this.swiper.update();
            }
        },
    },
};
</script>

<style></style>
