<template>
    <div v-cloak class="bg-gray-50 shadow-md rounded-sm p-3">
        <p class="text-gray-900 font-semibold">{{ message.Title }}</p>
        <p class="text-gray-600 text-xs">{{ message.Content }}</p>
    </div>
</template>

<script>
export default {
    props: [
        'message'
    ]
}
</script>

<style>

</style>
