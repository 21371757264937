<template>
    <div class="container-auth min-h-screen py-4 w-full flex justify-center items-center bg-gray-100 flex-col space-y-4">
        <img src="img/logo_green.png" alt="" width="220" class="mx-auto">

        <div class="card-auth bg-white shadow p-3 md:p-4 w-full md:w-2/5">
            <h1 class="text-center text-xl mb-4 font-display">Daftar Akun</h1>

            <div class="grid grid-cols-1 gap-y-4 mb-4">
                <div>
                    <select v-model="forms.profile_type" class="w-full p-3 text-lg font-body bg-white border-gray-300 border focus:outline-none">
                        <option value="1">Buyer / Tenant</option>
                        <option value="2">Seller / Landlord</option>
                        <option value="3">Broker</option>
                        <option value="4">Other</option>
                    </select>
                </div>
                <div>
                    <input v-model="forms.name" type="text" class="w-full p-3 border-gray-300 border focus:outline-none text-lg font-body" placeholder="Nama Lengkap">
                </div>
                <div>
                    <input v-model="forms.email" type="email" class="w-full p-3 border-gray-300 border focus:outline-none text-lg font-body" placeholder="Alamat Surel">
                </div>
                <div class="relative">
                    <input v-model="forms.password" :type="isPassShow ? 'text' : 'password'" class="w-full p-3 border-gray-300 border focus:outline-none text-lg font-body" placeholder="Password">
                    <button @click="isPassShow = !isPassShow" :class="'w-5 h-5 place-items-center grid-cols-1 rounded-full border border-gray-300 absolute top-0 bg-white mt-4 right-2 p-1 ' + (isPassShow ? 'hidden' : 'grid')"><i class="fas fa-eye text-gray-500"></i></button>
                    <button @click="isPassShow = !isPassShow" :class="'w-5 h-5 place-items-center grid-cols-1 rounded-full border border-gray-300 absolute top-0 bg-white mt-4 right-2 p-1 ' + (isPassShow ? 'grid' : 'hidden')"><i class="fas fa-eye-slash text-gray-500"></i></button>
                </div>
                <div>
                    <input v-model="forms.password_confirm" :type="isPassShow ? 'text' : 'password'" class="w-full p-3 border-gray-300 border focus:outline-none text-lg font-body" placeholder="Konfirmasi Password">
                </div>
                <div>
                    <input v-model="forms.phone" type="text" class="w-full p-3 border-gray-300 border focus:outline-none text-lg font-body" placeholder="Nomor Hp">
                </div>
                <button class="bg-green text-white text-lg text-center py-4" @click="register()">
                    <i :class="'fas fa-spinner fa-spin ' + (loading ? '' : 'hidden')"></i>
                    Daftar
                </button>
            </div>

            <slot></slot>

        </div>

    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
    data(){
        return {
            forms: {
                profile_type: 1,
                name: '',
                email: '',
                password: '',
                password_confirm: '',
                phone: '',
            },
            loading: false,
            isPassShow: false
        }
    },
    methods:{
        alertError(message = "Terjadi Kesalahan"){
            this.loading = false;
            Swal.fire({
                title: '<span class="text-red">Gagal mendaftar<span>',
                text: message,
                confirmButtonColor: '#006400',
            });
        },
        register(){
            const isValidRequired =  this.validateRequired();
            const isValidPassword = this.validatePassword();
            if (isValidRequired == false) {
                this.alertError("Harap lengkapi form")
                return;
            }

            if (isValidPassword == false) {
                this.alertError("Password tidak sama");
                return;
            }


            this.loading = true;
            let config = {
                method : "post",
                url: `/api/register`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: this.forms
            };
            axios(config)
                .then(function(res){
                     if (res.data.message == 'Register Success') {
                        Swal.fire({
                            title: '<span class="text-green">Hampir selesai<span>',
                            confirmButtonColor: '#006400',
                            html: `Kami telah mengirim email ke ${data.email} <br> Silakan periksa email Anda untuk memverifikasi akun Anda`,
                        });
                    }else{
                        Swal.fire({
                            title: '<span class="text-red">Gagal mendaftar<span>',
                            text: res.data.message,
                            confirmButtonColor: '#006400',
                        });
                    }
                }).finally(() => {
                    this.loading = false;
                })

        },
        validateRequired(){
            const forms = this.forms;
            for (const key in forms) {
                if (forms[key] == '' || forms[key] == null || forms[key] == undefined) {
                    return false;
                }
            }
            return true;
        },
        validatePassword(){
            if (this.forms.password != this.forms.password_confirm) {
                return false;
            }
        }
    }
}
</script>

<style>

</style>
