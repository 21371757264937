<template>
    <div class="flex flex-col space-y-3" v-cloak v-if="files.length > 0">
        <div v-if="errorImage.status"><small class="error">{{ errorImage.message }}</small></div>
        <div v-for="(file, index) in files" :key="index" class="flex justify-between items-center bg-gray-100 shadow-lg p-2 text-black text-xs animate__animated animate__fadeIn">
            <span>{{ file.name }} ({{ prettyByte(file.size) }} ) </span>
            <button @click="removeFile(index)" type="button" title="Remove"><i class="fas fa-trash text-red-fallback"></i></button>
        </div>
    </div>
    <div v-else>
        <p>Tidak ada gambar yang di pilih</p>
    </div>
</template>

<script>
import {bytesToSize} from '../../config/utils';
export default {
    props: [
        'files', 'preview', 'errorImage'
    ],
    methods: {
        prettyByte(val){
            return bytesToSize(val);
        },
        removeFile(fileKey){
            this.$emit('removed', fileKey)
        },
    }
}
</script>

<style>

</style>
