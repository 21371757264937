<template>
    <div class="w-full px-4">
        <h6 class="text-center font-semibold text-green">LOGIN</h6>
        <div class="overflow-hidden space-y-7">
            <div class="float-form-input green">
                <input name="email" type="email" placeholder=" " class="form" />
                <label for="email">Email</label>
            </div>

            <div class="float-form-input green">
                <input name="password" id="curr_password" type="password" placeholder=" " class="form" />
                <label for="password">Password</label>
                <span class="eyePass" data-target="#curr_password"><i class="icon pass-eye"></i></span>
            </div>

            <button class="rounded-sm py-2 w-full text-center font-medium text-base bg-green text-white">Login</button>
            <a class="text-sm text-green font-semibold open-modal" href="#" data-modal="#forgot-password-modal">Lupa Password?</a>
        </div>

        <p>Belum memiliki akun? <a href="#" data-modal="#register-modal" class="text-green font-semibold open-modal">Daftar disini</a> gratis</p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
