<template>
    <div class="pt-3">
        <div class="grid grid-cols-1 gap-y-4">
            <div class="flex justify-between items-start border-b pb-2">
                <div class="flex space-x-2 items-center w-full">
                    <div
                        class="hidden md:flex items-center border-2 w-14 h-14 justify-center"
                        :class="borderColor + ' ' + textColor"
                    >
                        <PropertiIcon :category="property.EngTypeName" />
                    </div>
                    <div>
                        <p
                            class="text-base md:text-lg font-semibold tracking-tight font-display"
                            v-text="title"
                        ></p>
                        <p class="tracking-tight text-xs md:text-base">
                            sekitar {{ property.Address }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="w-full h-60 md:h-323px mb-2">
                <GmapMap
                    ref="propertyMap"
                    :center="{
                        lat: Number(property.Latitude),
                        lng: Number(property.Longitude),
                    }"
                    :zoom="18"
                    style="width: 100%; height: 100%"
                    :options="map_option"
                >
                    <GmapMarker
                        :position="{
                            lat: Number(property.Latitude),
                            lng: Number(property.Longitude),
                        }"
                    />
                </GmapMap>
            </div>

            <div>
                <div class="mb-4 flex justify-start">
                    <!-- ShareThis BEGIN -->
                    <div class="sharethis-inline-share-buttons"></div>
                    <!-- ShareThis END -->

                    <!-- Go to www.addthis.com/dashboard to customize your tools -->
                    <!-- <div class="addthis_inline_share_toolbox"></div> -->
                    <!-- ShareThis BEGIN -->
                    <!-- <div class="sharethis-inline-share-buttons"></div> -->
                    <!-- ShareThis END -->
                </div>
                <p
                    class="text-base md:text-xl font-semibold border-b pb-2 mb-2 font-display"
                >
                    <span class="icon overview"></span>Ikhtisar
                </p>

                <div class="flex justify-between mb-4">
                    <p class="text-sm md:text-base">
                        Dicari oleh
                        <span v-bind:class="textColor">{{
                            property.RequesterTypeName
                        }}</span>
                    </p>
                    <div class="text-right text-sm md:text-base font-display">
                        <p v-bind:class="textColor">ID Wanted</p>
                        <p>{{ property.UniqueCode }}</p>
                    </div>
                </div>

                <div class="mb-4">
                    <p
                        class="text-base md:text-lg font-semibold font-display"
                        v-bind:class="textColor"
                        v-text="title"
                    ></p>
                    <p class="text-sm md:text-base">
                        {{ property.TypeName }} disekitar radius {{ kmRd }}
                    </p>
                </div>

                <div class="mb-4 flex justify-between">
                    <div>
                        <p
                            class="text-base md:text-lg font-semibold font-display"
                            v-bind:class="textColor"
                        >
                            Kriteria Pencarian
                        </p>

                        <div class="text-sm md:text-base">
                            <div v-cloak v-show="property.MinLandSize">
                                <p>
                                    Luas Tanah : {{ property.MinLandSize }} -
                                    {{ property.MaxLandSize }} m<sup>2</sup>
                                </p>
                            </div>
                            <div v-cloak v-show="property.MinBuildingSize">
                                <p>
                                    Luas Bangunan : {{ property.MinBuildingSize }} -
                                    {{ property.MaxBuildingSize }} m<sup>2</sup>
                                </p>
                                <p></p>
                            </div>
                            <div v-cloak v-show="property.MinPrice">
                                <p>
                                    Harga : {{ conCur(property.MinPrice) }} -
                                    {{ conCur(property.MaxPrice) }}
                                </p>
                            </div>
                        </div>
                        <p class="text-sm md:text-base">
                            Prioritas alamat : {{ property.PreferredAddress }}
                        </p>
                        <p class="text-sm md:text-base">
                            Deskripsi : {{ property.WantedDescription }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropertiIcon from "../Icons/PropertyIcon.vue";

export default {
    props: ["property"],
    data() {
        return {
            CategoryName: "",
            map_option: {
                streetViewControl: false,
                rotateControl: false,
            },
            title: "",
        };
    },
    components: {
        PropertiIcon,
    },
    computed: {
        // typeImage(){
        //     let path = '/img/type/'
        //     let type = this.property.TypeName;

        //     if (type) {
        //         return home;
        //     }else{
        //         return path+'Others.png';
        //     }

        // },
        bgColor: function () {
            let type = this.CategoryName;
            if (type == "Residential") {
                return "bg-green-light";
            }
            if (type == "Commercial") {
                return "bg-red";
            }
            if (type == "Industrial") {
                return "bg-orange";
            }
        },
        borderColor: function () {
            let type = this.CategoryName;
            if (type == "Residential") {
                return "border-green-light";
            }
            if (type == "Commercial") {
                return "border-red";
            }
            if (type == "Industrial") {
                return "border-orange";
            }
        },
        textColor: function () {
            let type = this.CategoryName;
            if (type == "Residential") {
                return "text-green-light";
            }
            if (type == "Commercial") {
                return "text-red";
            }
            if (type == "Industrial") {
                return "text-orange";
            }
        },
        kmRd: function () {
            const km = this.property.RadiusLocation / 1000;
            return km.toFixed(1) + " km";
        },
    },
    mounted() {
        let title = `Dicari ${this.property.TypeName} untuk di${this.property.TTName}`;
        title = _.startCase(_.toLower(title));
        this.title = title;
        this.CategoryName = this.property.CategoryName;
    },
    methods: {
        conCurLabel: function (data) {
            let num = String(parseInt(data)).slice(0, -6);
            return `${num}Jt`;
        },
        conCur: function (number) {
            console.log(number);
            const RP = (value) =>
                currency(value, {
                    symbol: "Rp. ",
                    precision: 0,
                    decimal: ".",
                    seperator: ",",
                });
            return RP(Number(number)).format();
        },
        panMap: function (params) {
            this.$refs.propertyMap.panTo({
                lat: 47.912867,
                lng: 106.910723,
            });
        },
    },
};
</script>

<style></style>
