import axios from "axios";

const baseUrl = process.env.MIX_CMS_BASE_URL_API || "https://devcms.closebuy.asia/api";

class CmsRepository {
    constructor(){
        this.baseUrl = baseUrl;
        this.AXIOS = null;
        this.adds = null;
    }
    //axios create
    setAxios(){
        this.AXIOS = axios.create({
            baseURL: this.baseUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Requested-With": "XMLHttpRequest",
                token: "closebuy-here2022"
            }
        });
    }
    //get all adds
    getAdds(){
        return this.AXIOS.get("/cb_resources/adds");
    }
    //get all sliders
    getSliders(){
        return this.AXIOS.get("/cb_resources/sliders");
    }

    getSearchMenu(){
        return this.AXIOS.get("/cb_resources/get_search_groups");
    }
}

export default new CmsRepository();


