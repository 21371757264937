const { default: axios } = require("axios");

const baseUrl =
    process.env.MIX_CMS_BASE_URL_API || "https://devcms.closebuy.asia/api";

const propertyAxios = axios.create({
    baseURL: baseUrl
});

export function getTypesProperty() {
    return propertyAxios.get("/property-type");
}
