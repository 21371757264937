<template>
    <div>
        <footer class="footer">
            <div class="section-container pb-3">
                <div class="content-wrapper px-5 md:px-0">
                    <div class="column-md">
                        <img
                            src="/img/logo1_result.png"
                            alt=""
                            width="180"
                            class="mb-4"
                        />

                        <p class="mb-2">Berlangganan Buletin CloseBuy</p>
                        <div class="w-full relative">
                            <form action="" class="mb-2 md:mb-0">
                                <input
                                    type="text"
                                    class="text-black bg-gray-50 focus:outline-none p-2 w-full"
                                    placeholder="Ketik email Anda untuk berlangganan"
                                />
                                <button
                                    type="submit"
                                    class="absolute px-4 inset-y-0 right-0 text-gray-900 border-l border-gray-900 bg-white"
                                >
                                    <i class="fas fa-envelope"></i>
                                </button>
                            </form>
                        </div>
                    </div>

                    <div
                        class="col-auto lg:col-span-3 flex justify-start lg:justify-end flex-wrap lg:flex-nowrap space-y-2 lg:space-y-0"
                    >
                        <div class="column-sm border-b lg:border-b-0 md:block hidden">
                            <p class="title"><strong>CloseBuy</strong></p>
                            <!-- <a href="/page/faq" class="mb-3 block">FAQ</a> -->
                            <a href="#" class="mb-3 block">FAQ</a>
                            <a href="#" class="mb-3 hidden">Event</a>
                            <!-- <a href="/page/about" class="mb-3 block">Tentang ColseBuy</a> -->
                            <a href="#" class="mb-3 block">Tentang CloseBuy</a>
                        </div>

                        <div class="column-sm border-b lg:border-b-0 md:block hidden">
                            <p class="title"><strong>Policy</strong></p>
                            <a href="#" class="mb-3 block">Ketentuan Layanan</a>
                            <a href="#" class="mb-3 block">Kebijakan Privasi</a>
                            <!-- <a href="/page/join-agent" class="mb-3 block">Join Agent</a> -->
                            <a href="#" class="mb-3 block">Join Agent</a>
                        </div>

                        <div class="column-sm">
                            <p class="title"><strong>Contact Us </strong></p>
                            <div class="mb-4">
                                <a class="block mb-3" href="mailto:support@closebuy.asia">
                                    <i class="far fa-envelope text-lg"></i>
                                    <span>support@closebuy.asia</span>
                                </a>
                                <a
                                    class="block mb-3"
                                    href="//instagram.com/closebuy.asia"
                                    target="_blank"
                                >
                                    <i class="fab fa-instagram text-lg"></i>
                                    <span>closebuy_property</span>
                                </a>
                                <a
                                    class="block mb-3"
                                    href="//facebook.com/closebuy.asia"
                                    target="_blank"
                                >
                                    <i class="fab fa-facebook text-lg"></i>
                                    <span>closebuy_property</span>
                                </a>
                                <a href="/contact-us">
                                    <i class="far fa-question-circle"></i>
                                    <span>Butuh Bantuan?</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-container py-3">
                <div class="text-xs text-center tracking-tight font-light">
                    © 2023 CloseBuy.asia, All rights reserved
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
