<template>
    <div class="section-faq">
        <VueFaqAccordion
            :items="myItems" class="text-base" activeColor="#006400" fontColor="#000" borderColor="#006400"
        />
    </div>
</template>

<script>
import VueFaqAccordion from 'vue-faq-accordion'

export default {
    components: {
        VueFaqAccordion
    },
    data () {
        return {
        myItems: [
                {
                    title: 'How many time zones are there in all?',
                    value: 'Given a 24-hour day and 360 degrees of longitude around the Earth',
                    // category: 'Tab-1'
                },
                {
                    title: 'How long is a day and year on Venus?',
                    value: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.',
                    // category: 'Tab-1'
                },
                {
                    title: 'What animal smells like popcorn?',
                    value: 'Binturongs smell like popcorn.',
                    // category: 'Tab-1'
                }
            ]
        }
    }
}
</script>

<style lang="scss">
    .section-faq{
        margin-top: 30px;
        .faq{
            .faq-wrapper{
                max-width: none !important;
            }
        }
    }
</style>
