<template>
    <tr v-if="value">
        <th>{{ title }}</th>
        <td v-if="units === 'price'"><money class="hidden" v-model.lazy="price" v-bind="money"/>{{price}}</td>
        <td v-html="unitValue(value, units || null)" v-else></td>
    </tr>
</template>

<script>
import { Money } from "../../../plugins/v-money";

export default {
    props : [
        'title', 'value', 'units'
    ],
    data(){
        return{
            price: 0,
            money: {
                decimal: '',
                thousands: '.',
                prefix: 'Rp. ',
                precision: 0,
                masked: true
            }
        }
    },
    mounted(){
        if (this.units === 'price') {
            this.price = this.value;
        }
    },
    components: {Money},
    methods: {
        unitValue(value, units){
            if (units) {
                switch (units) {
                    case 'long':
                        value = value + ' km';
                        break;
                    case 'sizing':
                        value = value + ' m<sup>2</sup>';
                        break;
                    case 'price':
                        value = 'Rp. '+value;
                        break;
                    default:
                        break;
                }
            }
            return value;
        }
    }
}
</script>

<style>

</style>
