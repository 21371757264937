const { default: axios } = require("axios");

const baseUrl =
    process.env.MIX_CMS_BASE_URL_API || "https://devcms.closebuy.asia/api";

const zoneAxios = axios.create({
    baseURL: baseUrl
});

export function getAllProvince() {
    return zoneAxios.get('/zone/province');
}

export function getCityByProvince(id) {
    return zoneAxios.get(`/zone/city/${id}`);
}

export function getDistrictByCity(id) {
    return zoneAxios.get(`/zone/district/${id}`);
}

export function getVillageByDistrict(id) {
    return zoneAxios.get(`/zone/village/${id}`);
}
