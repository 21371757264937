<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)" class="property-form">
        <div class="mx-0 py-8">
            <div class="wanted-steps stepper">
                <div class="item" v-bind:class="{ done: formIndex >= 1 }">
                    <p class="title">Step 1</p>
                    <p class="font-semibold">Detail Pencarian</p>
                </div>
                <div class="item" v-bind:class="{ done: formIndex >= 2 }">
                    <p class="title">Step 2</p>
                    <p class="font-semibold">Fitur Pencarian</p>
                </div>
                <div class="item" v-bind:class="{ done: formIndex == 3 }">
                    <p class="title">Step 3</p>
                    <p class="font-semibold">Ringkasan Pencarian</p>
                </div>
            </div>
        </div>

        <!-- <div class="wanted-form-template-one form-step"  v-bind:class="{current: formIndex == 1}"> -->
            <ValidationObserver v-if="formIndex === 1">
                <div class="wanted-form-template-one form-step" v-bind:class="{ current: formIndex === 1}">
                    <div class="space-y-4">
                        <div>
                            <ValidationProvider name="Deskripsi" rules="required" v-slot="{ errors }">
                                <label for="description" class="mb-0.5 text-sm">Kriteria Permintaan <span class="form-helper" data-tippy-content="Isi Iklan. Contoh: Dicari untuk di beli rumah! Rumah bergaya arsitektur American Classic ini dibangun di atas lahan 1.000 m2 menghadap Timur. Memiliki 5 Kamar Tidur, 4 Kamar Mandi dan Garasi untuk 3 mobil. Bagian Belakang Rumah terdapat Kolam Renang dan Gazebo."></span></label>
                                <textarea v-model="forms.description" placeholder="Isi Iklan. Contoh: Contoh: Dicari untuk di beli rumah! Rumah bergaya arsitektur American Classic ini dibangun di atas lahan 1.000 m2 menghadap Timur. Memiliki 5 Kamar Tidur, 4 Kamar Mandi dan Garasi untuk 3 mobil. Bagian Belakang Rumah terdapat Kolam Renang dan Gazebo." class="form-input-property" rows="5"></textarea>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div v-if="provinceData">
                            <ValidationProvider name="Provinsi" rules="required" v-slot="{ errors }">
                                <label class="mb-0.5 text-sm" for="province">Pilih Provinsi</label>
                                <multiselect v-model="forms.province" track-by="text" label="text" :options="provinceData" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pilih Provinsi">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div v-cloak v-show="cityData.length > 0">
                            <label class="mb-0.5 text-sm" for="province">Pilih Kota/Kabupaten</label>
                            <ValidationProvider name="Kota/Kabupaten" rules="required" v-slot="{ errors }">
                                <multiselect v-model="forms.city" track-by="text" label="text" :options="cityData" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pilih Kota/Kabupaten">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div v-cloak v-show="districtData.length > 0">
                            <label class="mb-0.5 text-sm" for="province">Pilih Kecamatan</label>
                            <multiselect v-model="forms.district" track-by="text" label="text" :options="districtData" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pilih Kecamatan">
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                            </multiselect>
                        </div>
                        <div v-cloak v-show="villageData.length > 0">
                            <label class="mb-0.5 text-sm" for="province">Pilih Desa/Kelurahan</label>
                            <multiselect v-model="forms.village" track-by="text" label="text" :options="villageData" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Pilih Desa/Kelurahan">
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                            </multiselect>
                        </div>
                        <div>
                            <ValidationProvider name="Alamat yang di tampilkan" rules="required" v-slot="{ errors }">
                                <label for="prefered_address" class="mb-0.5 text-sm">Alamat yang diutamakan <span class="form-helper" data-tippy-content="Isilah Alamat Pencarian yang akan ditampilkan pada halaman iklan Anda. Silahkan pilih lokasi pada peta di atas"></span></label>
                                <textarea v-model="forms.prefered_address" data-summary="#prefered_address_summary" data-error="Masukkan alamat property" type="text" name="prefered_address" id="prefered_address" class="form-input-property required"></textarea>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row-span-1" v-cloak v-show="forms.prefered_address && forms.province && forms.city">
                        <p class="text-gray-700 underline mb-3">Klik pada peta, untuk menentukan lokasi properti anda atau cari dengan memasukkan nama jalan lokasi yang diinginkan.</p>
                        <div class="map-container relative">
                            <GmapMap
                                    :center="map.center"
                                    :zoom="14"
                                    :options="{
                                        mapTypeControl: false,
                                        scaleControl: false,
                                        fullscreenControl: false,
                                        streetViewControl: false
                                    }"
                                    style="height: 450px"
                                    @click="mark"
                                >
                                <GmapMarker v-for="(marker, index) in map.markers"
                                    :key="index"
                                    :position="marker.position"
                                    :draggable=true
                                    @dragend="mark"
                                    />
                                />
                            </GmapMap>
                            <GmapAutocomplete @place_changed="setPlace" class="form-input-property w-3/4 mx-auto absolute inset-x-0 top-0 mt-2 required"></GmapAutocomplete>
                        </div>
                        <div class="hidden">
                            <textarea type="text" v-model="forms.address" name="address" id="address" class="form-input-property required"></textarea>
                        </div>
                        <div class="hidden">
                            <input type="text" v-model="forms.latitude" name="latitude" id="latitude" class="form-input-property required">
                        </div>
                        <div class="hidden">
                            <input type="text" v-model="forms.longitude" name="longitude" id="longitude" class="form-input-property required">
                        </div>
                    </div>
                </div>
            </ValidationObserver>

            <ValidationObserver v-if="formIndex === 2">
                <div class="wanted-form-template-two form-step" v-bind:class="{ current: formIndex === 2}">
                    <div class="row-span-6 grid grid-cols-1 gap-y-4 place-content-start">
                        <div v-cloak v-show="transactionData.length > 0">
                            <label class="mb-0.5 text-sm" for="province">Pilih Tipe Transaksi</label>
                            <ValidationProvider name="Tipe Transaksi" rules="required" v-slot="{ errors }">
                                <multiselect v-model="forms.transaction_type" track-by="text" label="text" :options="transactionData" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Pilih Tipe Transaksi">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label class="mb-0.5 text-sm" for="category">Kategori Properti</label>
                            <ValidationProvider name="Kategori Properti" rules="required" v-slot="{ errors }">
                                <multiselect v-model="forms.category" track-by="text" label="text" :options="categoryData" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Pilih Kategori Properti">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label class="mb-0.5 text-sm" for="category">Tipe Properti</label>
                            <ValidationProvider name="Tipe Properti" rules="required" v-slot="{ errors }">
                                <multiselect v-model="forms.type" track-by="text" label="text" :options="typePropertyData" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Pilih Tipe Properti">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div>
                            <label class="mb-0.5 text-sm" for="requester_type_wanted">Pencari</label>
                            <ValidationProvider name="Tipe Pencari" rules="required" v-slot="{ errors }">
                                <multiselect class="custom-select" v-model="forms.requester_type" track-by="text" label="text" :options="typeRequesterData" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Pilih Tipe Pencari">
                                    <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
                                </multiselect>
                                <small class="error">{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                        <div v-cloak v-show="forms.requester_type && forms.requester_type.value === 2">
                            <label for="company">Nama Perusahaan</label>
                            <input type="text" v-model="forms.company" id="company" class="form-input-property" placeholder="Masukkan Nama Perusahaan">
                        </div>
                        <div v-cloak v-show="forms.requester_type && forms.requester_type.value === 2">
                            <label for="job_position">Jabatan</label>
                            <input type="text" v-model="forms.job_position" id="job_position" class="form-input-property" placeholder="Masukkan Nama Jabatan Anda">
                        </div>
                    </div>

                    <div class="row-span-6">
                        <div class="grid grid-cols-12 gap-y-4 gap-x-4 place-content-start">
                            <div class="col-span-6">
                                <ValidationProvider name="Minimal Harga" rules="required|min_value:1" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="min_price">Minimal Harga</label>
                                    <money v-model="forms.min_price" v-bind="money" class="form-input-property"></money>
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-6">
                                <ValidationProvider name="Maksimal Harga" :rules="`required|min_value:${forms.min_price}`" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="max_price">Maksimal Harga</label>
                                    <money v-model="forms.max_price" v-bind="money" class="form-input-property"></money>
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-6" v-cloak v-show="forms.type && (LandSizing && BuildingSizing) || forms.type && BuildingSizing">
                                <ValidationProvider name="Minimal Luas Bangunan" rules="required_if:BuildingSizing,true|numeric|min_value:1" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="building_size">Minimal Luas Bangunan m<sup>2</sup></label>
                                    <input v-model="forms.min_building_size" placeholder="Min Luas Bangunan (m2)" type="text" id="min_building_size" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-6" v-cloak v-show="forms.type && (LandSizing && BuildingSizing) || forms.type && BuildingSizing">
                                <ValidationProvider name="Maksimal Luas Bangunan" :rules="`required_if:BuildingSizing,true|numeric|min_value:${forms.min_building_size}`" v-slot="{ errors }">
                                        <label class="mb-0.5 text-sm" for="building_size">Maksimal Luas Bangunan m<sup>2</sup></label>
                                        <input v-model="forms.max_building_size" placeholder="Maks Luas Bangunan (m2)" type="text" id="max_building_size" class="form-input-property">
                                        <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-6" v-cloak v-show="forms.type && (LandSizing && BuildingSizing) || forms.type && LandSizing">
                                <ValidationProvider name="Minimal Luas Tanah" rules="required_if:LandSizing,true|numeric|min_value:1" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="min_land_size">Minimal Luas Tanah m<sup>2</sup></label>
                                    <input v-model="forms.min_land_size" placeholder="Min Luas Tanah (m2)" type="text" id="min_land_size" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-6" v-cloak v-show="forms.type && (LandSizing && BuildingSizing) || forms.type && LandSizing">
                                <ValidationProvider name="Maksimal Luas Tanah" :rules="`required_if:LandSizing,true|numeric|min_value:${forms.min_land_size}`" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="max_land_size">Maksimal Luas Tanah m<sup>2</sup></label>
                                    <input v-model="forms.max_land_size" placeholder="Maksimal Luas Tanah (m2)" type="text" id="max_land_size" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-6" v-cloak v-show="forms.type && UnitSizing">
                                <ValidationProvider name="Minimal Luas Tanah" rules="required_if:UnitSizing,true|numeric|min_value:1" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="min_unit_size">Minimal Luas Unit m<sup>2</sup></label>
                                    <input v-model="forms.min_unit_size" placeholder="Minimal Luas Unit (m2)" type="text" name="min_unit_size" id="min_unit_size" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-6" v-cloak v-show="forms.type && UnitSizing">
                                <ValidationProvider name="Minimal Luas Tanah" :rules="`required_if:UnitSizing,true|numeric|min_value:${forms.min_unit_size}`" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="max_unit_size">Maksimal Luas Unit m<sup>2</sup></label>
                                    <input v-model="forms.max_unit_size" placeholder="Maksimal Luas Unit (m2)" type="text" id="max_unit_size" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                            <div class="col-span-12">
                                <ValidationProvider name="Radius pencarian" rules="required|numeric|min_value:1" v-slot="{ errors }">
                                    <label class="mb-0.5 text-sm" for="radius">Radius Pencarian (KM)</label>
                                    <input v-model="forms.radius" placeholder="Radius Pencarian (KM)" type="text" id="radius" class="form-input-property">
                                    <small class="error">{{ errors[0] }}</small>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidationObserver>

            <ValidationObserver v-if="formIndex === 3">
                <div class="wanted-form-template-three form-step" v-bind:class="{ current: formIndex === 3}">
                    <table class="tables">
                        <Row title="Deskripsi" :value="forms.description || null"/>
                        <Row title="Provinsi" :value="forms.province.text || null"/>
                        <Row title="Kota/Kabupaten" :value="forms.city.text || null"/>
                        <Row title="Kecamatan" :value="forms.district ? forms.district.text : null"/>
                        <Row title="Desa/Kelurahan" :value="forms.village ? forms.village.text : null"/>
                        <Row title="Alamat yang diutamakan" :value="forms.prefered_address || null"/>
                        <Row title="Latitude" :value="forms.latitude || null"/>
                        <Row title="Longitude" :value="forms.longitude || null"/>
                        <Row title="Tipe Transaksi" :value="forms.transaction_type.text || null"/>
                        <Row title="Kategori Properti" :value="forms.category.text || null"/>
                        <Row title="Tipe Properti" :value="forms.type.text || null"/>
                        <Row title="Pencari" :value="forms.requester_type.text || null"/>
                        <Row title="Perusahaan" :value="forms.company || null"/>
                        <Row title="Jabatan" :value="forms.job_position || null"/>
                        <Row units="price" title="Minimal Harga" :value="forms.min_price || null"/>
                        <Row units="price" title="Maksimal Harga" :value="forms.max_price || null"/>
                        <Row units="sizing" title="Minimal Ukuran Bangunan" :value="forms.min_building_size || null"/>
                        <Row units="sizing" title="Maksimal Ukuran Bangunan" :value="forms.max_building_size || null"/>
                        <Row units="sizing" title="Minimal Ukuran Tanah" :value="forms.min_land_size || null"/>
                        <Row units="sizing" title="Maksimal Ukuran Tanah" :value="forms.max_land_size || null"/>
                        <Row units="sizing" title="Minimal Ukuran Unit" :value="forms.min_unit_size || null"/>
                        <Row units="sizing" title="Maksimal Ukuran Unit" :value="forms.max_unit_size || null"/>
                        <Row units="long" title="Radius Pencarian" :value="forms.radius || null"/>
                    </table>
                    </div>
            </ValidationObserver>

            <div class="flex justify-between">
                <button class="prev-button" type="button" @click="prev()" v-cloak v-show="formIndex > 1">Sebelumnya</button>
                <button class="next-button justify-self-end" type="submit" v-if="formIndex < 3">Lanjut</button>
                <button class="submit-button justify-self-end" type="submit" v-else>Submit</button>
            </div>
        </form>
        <loading :loading="loading"/>
        <modal :showing="tosmodal" @close="tosmodal = false">
            <p class="font-semibold text-lg" slot="header">
                Konfirmasi & Persetujuan
            </p>
            <div slot="body">
                    <p class="mb-2">1. Saya setuju dengan Syarat dan Ketentuan serta Kebijakan Privasi yang berlaku</p>
                    <p class="mb-2">2. Saya memberikan konfirmasi bahwa informasi yang saya berikan untuk ditayangkan adalah benar dan tunduk terhadap UU ITE</p>
                    <p class="mb-2">3. Saya setuju untuk menggunakan fitur <b>hubungi kami</b> dan contact <b>CloseBuy Advisor</b> sebagai media komunikasi saya dengan pihak CloseBuy.</p>
                    <p class="mb-2">4. Saya menyetujui untuk menggunakan Escrow Account (Trust Account) CloseBuy maupun pihak yang ditunjuk oleh Calon Pembeli</p>
                    <p class="mb-2">5. Saya mengerti bahwa listing di website CloseBuy adalah Gratis dan berlaku unlimited.</p>
                    <p class="mb-2">6. Saya mengerti dan menyetujui bahwa terdapat service fee / komisi ketika transaksi berhasil dilakukan melalui CloseBuy, Agen CloseBuy, maupun pihak yang berafiliasi lainnya dengan CloseBuy. Informasi melalui besaran biaya komisi (service fee) akan diberikan ketika listing saya disetujui penayangannya.</p>
                <div class="space-x-1 flex">
                    <button type="button" class="bg-green shadow px-2 py-1 text-sm text-white font-semibold mr-3" @click="sendData">Setuju</button>
                    <button type="button" class="bg-gray-200 shadow px-2 py-1 text-sm text-black font-semibold" @click="hideTosModal">Tidak Setuju</button>
                </div>
            </div>
        </modal>
        <modal :showing="tosrejectmodal" @close="tosrejectmodal = false">
            <div slot="body">
                <p class="mb-2">Apakah Anda yakin ingin membatalkan tayangan pencarian Anda?</p>
                <div class="space-x-1 flex">
                    <button type="button" class="bg-green shadow px-2 py-1 text-sm text-white font-semibold mr-3" @click="goToWantedMap">Ya</button>
                    <button type="button" class="bg-gray-200 shadow px-2 py-1 text-sm text-black font-semibold" @click="showTosModal">Tidak</button>
                </div>
            </div>
        </modal>
    </ValidationObserver>
</template>

<script>
import SlimSelect from "slim-select";
import Multiselect from 'vue-multiselect'
import { getAllProvince, getCityByProvince, getDistrictByCity, getVillageByDistrict } from "../../api/zoneApi";
import { getPropertyData, getCategoryData, getWantedTransaction, getRequesterType } from "../../config/option";
import Row from '../Utils/Table/RowComponent.vue';
import axios from 'axios';
import Loading from '../Utils/LoadingStats.vue';
import Modal from '../Utils/ModalComponent.vue';
import Swal from 'sweetalert2';
import { Money } from "../../plugins/v-money";


export default {
    props: [
        'token'
    ],
    data: () => {
        return{
            loading: false,
            tosmodal: false,
            tosrejectmodal: false,
            UnitSizing: false,
            LandSizing: false,
            BuildingSizing: false,
            formIndex: 1,
            provinceData: [],
            cityData: [],
            districtData: [],
            villageData: [],
            transactionData: [],
            categoryData: [],
            typePropertyData: [],
            typeRequesterData: [],
            selectProvinceWanted: null,
            selectCityWanted: null,
            selectDistrictWanted: null,
            selectVillageWanted: null,
            selectTransactionTypeFormWanted: null,
            forms: {
                description: null,
                province: null,
                city: null,
                district: null,
                village: null,
                prefered_address: null,
                transaction_type: null,
                category: null,
                type: null,
                requester_type: null,
                company: null,
                job_position: null,
                latitude: null,
                longitude: null,
                address: null,
                min_price: 0,
                max_price: 0,
                min_building_size: null,
                max_building_size: null,
                min_land_size: null,
                max_land_size: null,
                min_unit_size: null,
                max_unit_size: null,
                radius: null
            },
            map: {
                center: {lat: -6.200000, lng: 106.816666 },
                markers: [],
                place: null
            },
            money: {
                decimal: '',
                thousands: '.',
                prefix: 'Rp. ',
                precision: 0,
                masked: false
            }
        }
    },
    components: {
        Multiselect,
        Row,
        Loading,
        Modal,
        Money
    },
    computed: {
        province() {
            return this.forms.province || null;
        },
        city(){
            return this.forms.city || null;
        },
        district(){
            return this.forms.district || null;
        },
        village(){
            return this.forms.village || null;
        },
        category(){
            return this.forms.category;
        },
        type(){
            return this.forms.type || null;
        },
        latitude(){
            return this.forms.latitude || null;
        }

    },
    watch: {
        province(val) {
            this.forms.city = null;
            this.forms.district = null;
            this.forms.village = null;
            this.cityData = [];
            this.districtData= [];
            this.villageData= [];
            if (val.data) {
                this.provinceSelected(val);
                if (val.data.lat && val.data.long) {
                    let location = {
                        lat: parseFloat(val.data.lat),
                        lng: parseFloat(val.data.long)
                    }
                    this.setMarker(location);
                }
            }
        },
        city(val){
            this.forms.district = null;
            this.forms.village = null;
            this.districtData= [];
            this.villageData= [];
            if (val.data) {
                this.citySelected(val);
                if (val.data.lat && val.data.long) {
                    let location = {
                        lat: parseFloat(val.data.lat),
                        lng: parseFloat(val.data.long)
                    }
                    this.setMarker(location);
                }
            }
        },
        district(val){
            this.forms.village = null;
            this.villageData= [];
            if (val.data) {
                this.districtSelected(val);
                if (val.data.lat && val.data.long) {
                    let location = {
                        lat: parseFloat(val.data.lat),
                        lng: parseFloat(val.data.long)
                    }
                    this.setMarker(location);
                }
            }
        },
        village(val){
            if (val.data) {
                if (val.data.lat && val.data.long) {
                    let location = {
                        lat: parseFloat(val.data.lat),
                        lng: parseFloat(val.data.long)
                    }
                    this.setMarker(location);
                }
            }
        },
        category(val){
            this.typePropertyData = [];
            this.forms.type = null;
            let getPropertyType = getPropertyData();
            this.typePropertyData = _.filter(getPropertyType, propeprty => propeprty.category_id == val.value);
        },
        type(val){
            this.sizingToggle(val.value || null);
        },
    },
    created(){
        this.transactionData = getWantedTransaction();
        this.categoryData = getCategoryData();
        this.typeRequesterData = getRequesterType();
    },
    mounted(){
        this.provinceData = this.getProvince();
        this.user_token = this.$store.state.authToken;
    },
    methods: {
        onSubmit () {
            this.loading = true;
            if (this.formIndex === 3) {
                this.tosmodal = true;
            }else{
                this.formIndex++;
            }

            this.loading = false;

        },
        next(){
            console.log(this.forms);
        },
        prev(){
            if (this.formIndex < 2) {
                alert('Anda tidak dapat kembali di step ini');
                return;
            }
            this.formIndex--;
        },
        showTosModal(){
            this.tosrejectmodal = false;
            this.tosmodal = true;
        },
        goToWantedMap(){
            window.location = '/wanted';
        },
        hideTosModal(){
            this.tosmodal = false;
            this.tosrejectmodal = true;
        },
        sendData(){
            this.tosmodal =false;
            this.loading = true;
            const data = {
                    description: this.forms.description,
                    province: this.forms.province.value,
                    city: this.forms.city.value,
                    district: this.forms.district ? this.forms.district.value : 0,
                    village: this.forms.village ? this.forms.village.value : 0,
                    prefered_address: this.forms.prefered_address,
                    transaction_type: this.forms.transaction_type.value,
                    category: this.forms.category.value,
                    type: this.forms.type.value,
                    requester_type: this.forms.requester_type.value,
                    company: this.forms.company,
                    job_position: this.forms.job_position,
                    latitude: this.forms.latitude,
                    longitude: this.forms.longitude,
                    address: this.forms.address,
                    min_price: this.forms.min_price,
                    max_price: this.forms.max_price,
                    min_building_size: this.forms.min_building_size,
                    max_building_size: this.forms.max_building_size,
                    min_land_size: this.forms.min_land_size,
                    max_land_size: this.forms.max_land_size,
                    min_unit_size: this.forms.min_unit_size,
                    max_unit_size: this.forms.max_unit_size,
                    radius: this.forms.radius
                }
                const baseUrl = process.env.MIX_BASE_URL_API || "https://api.closebuy.asia/api";

                const insertWanted = axios.create({
                    baseURL: baseUrl,
                    headers: {'Authorization' : `Bearer ${this.token}`},
                });

                insertWanted.post('/wanted', data).then(response => {
                    console.log(response);
                    if (response.data) {
                        if (response.data.success) {
                            this.loading = false;
                            Swal.fire({
                                title: 'Berhasil Menambahkan Pencarian',
                                html: 'Terima kasih telah mengajukan pencarian properti, pantau status melalui <a href="/user/wanted">My List</a>',
                                icon: 'success'
                            });
                            window.setTimeout(function(){
                                window.location = "/user/wanted#";
                            }, 2000 );
                        }else{
                            this.loading = false;
                            console.log(response);
                            alert('Terjadi kesalahan saat membuat permintaan anda!');
                        }
                    }
                }).catch(failed => {
                    this.loading = false;
                    console.log(failed);
                    alert('Terjadi kesalahan saat membuat permintaan anda!');
                });
        },
        getProvince: function() {
            let provinceData = [];
            getAllProvince().then(response => {
                let resultProvince = response.data;
                resultProvince.forEach(element => {
                    let tmp = {
                        value: element.id,
                        text: element.name,
                        data: element.meta
                    };
                    provinceData.push(tmp);
                });
            });
            return provinceData;
        },
        provinceSelected(info){
            let cityData = [];
            getCityByProvince(info.value).then(response => {
                let resultCity = response.data;
                resultCity.forEach(element => {
                    let tmp = {
                        value: element.id,
                        text: element.name,
                        data: element.meta
                    };
                    cityData.push(tmp);
                });
            });
            this.cityData = cityData;
            return cityData;
        },
        citySelected(info){
            let districtData = [];
            getDistrictByCity(info.value).then(response => {
                let resultDistrict = response.data;
                resultDistrict.forEach(element => {
                    let tmp = {
                        value: element.id,
                        text: element.name,
                        data: element.meta
                    };
                    districtData.push(tmp);
                });
            });
            this.districtData = districtData;
            return districtData;
        },
        districtSelected(info){
            let villageData = [];
            getVillageByDistrict(info.value).then(response => {
                let resultVillage = response.data;
                resultVillage.forEach(element => {
                    let tmp = {
                        value: element.id,
                        text: element.name,
                        data: element.meta
                    };
                    villageData.push(tmp);
                });
            });
            this.villageData = villageData;
            return villageData;
        },
        sizingToggle(value = null){
            if (value) {
                if (value == 2 || value == 6 || value == 8) {
                    this.UnitSizing = true;
                    this.LandSizing = false;
                    this.BuildingSizing = false;
                }else if (value == 3 || value == 7 || value == 13 || value == 14) {
                    this.LandSizing = true;
                    this.UnitSizing = false;
                    this.BuildingSizing = false;
                }else{
                    this.BuildingSizing = true;
                    this.LandSizing = true;
                    this.UnitSizing = false;
                }
            }
            this.forms.min_building_size = null;
            this.forms.max_building_size = null;
            this.forms.min_land_size = null;
            this.forms.max_land_size = null;
            this.forms.min_unit_size = null;
            this.forms.max_unit_size = null;
        },
        setPlace(place) {
            console.log(place);
            this.map.place = place;
            if (this.map.place) {
                let location = {
                    lat: this.map.place.geometry.location.lat(),
                    lng: this.map.place.geometry.location.lng()
                }
                this.setMarker(location)
            }
        },
        mark(event) {
            let location = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            }
            this.setMarker(location);
        },
        setMarker(location){
            let geocoder = new google.maps.Geocoder();

            geocoder
                .geocode({ location: location }).then((response) => {
                    if (response.results[0]) {
                        this.map.center = location;
                        this.forms.address = response.results[0].formatted_address
                    } else {
                        window.alert("No results found");
                    }
                })
                .catch((e) => window.alert("Geocoder failed due to: " + e));
            this.map.markers = [];
            this.forms.latitude = location.lat;
            this.forms.longitude = location.lng;
            this.map.markers.push({
                position: {
                    lat: location.lat,
                    lng: location.lng,
                }
            });
            this.map.center = location;
            this.map.place = null;
        }
    }
}
</script>


<style>

</style>
