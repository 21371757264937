<template>
    <section class="w-full h-screen pt-14 bg-gray-50">
        <div class="flex flex-row h-full">

            <div class="map-section w-3/5 h-full" v-if="geoloc">
                <GmapMap
                    ref="projectmap"
                    :center="center"
                    :zoom="12"
                    map-type-id="roadmap"
                    style="width: 100%; height: 100%"
                    >
                </GmapMap>
            </div>

            <div class="content-section w-2/5">

            </div>

        </div>
    </section>
</template>

<script>
import Swal from 'sweetalert2';
import {gmapApi} from 'vue2-google-maps';
export default {
    data(){
        return{
            geoloc: false,
            properties: null,
            google: null
        }
    },
    computed: {
        center(){
            if (this.geoloc) {
                return {
                    lat: this.geoloc.coords.latitude, lng: this.geoloc.coords.longitude
                }
            }

            return {
                lat: 5, lng: 5
            }
        }
    },
    created(){
        if (!"geolocation" in navigator) {
            Swal.fire({
                title: 'Geolocation is not supported!',
                text: 'Your browser does not support geolocation.',
                type: 'warning'
            });
        }
        this.locate();
    },
    mounted(){
        if (this.geoloc) {
            this.loadData();
        }
        this.google = gmapApi;
    },
    methods: {
        locate() {
            navigator.geolocation.getCurrentPosition(pos => {
                this.geoloc = pos;
            }, err => {
                this.geoloc = false;
            });
        },
        loadData(){
            axios.get(`https://api.closebuy.asia/api/property/getAllListing`, {
                params: {
                    'latitude' : this.geoloc.coords.latitude,
                    'longitude' :  this.geoloc.coords.longitude,
                }
            }).then((response) => {
                this.properties = response.data.data;
                console.log(new google.maps.getCenter());
            });
        }
    }
}
</script>

<style>

</style>
