<template>
    <div v-if="loading">
        <div class="w-screen h-screen flex justify-center items-center fixed inset-0">
            <div class="bg-white text-center w-96 h-96 shadow flex justify-center items-center">
                <div>
                    <i class="fas fa-spinner fa-spin text-5xl text-green"></i>
                    <p>Mohon tunggu...</p>
                </div>
            </div>
        </div>
    </div>

    <div v-else>
        <!-- request finished -->
    </div>
</template>

<script>
export default {
    props: [
        'loading'
    ]
}
</script>

<style>

</style>
