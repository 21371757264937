<template>
    <div class="space-y-4">
        <listing :property="property"></listing>
        <wanted :property="property"></wanted>
    </div>
</template>

<script>
import Listing from './Listing/Component.vue';
import Wanted from './Wanted/Component.vue';

export default {
    props: ['property', 'sort'],
    components: {
        'listing': Listing,
        'wanted': Wanted
    }

}
</script>

<style>

</style>
