<template>
    <div v-if="wantedLoaded && wanted.count > 0">
        <section>
            <h5 class="section-title title-green">Closeby Wanted</h5>
            <div class="wanted-wrapper relative">
                <swiper class="swiper wanted-list" :options="swiperOption" ref="swiperWanted" @click="redirectMe(popular.Name, popular.PropertyID)">
                    <swiper-slide v-for="wanted in wanted.data" :key="wanted.PropertyID">
                        <wanted-card :type="wanted.TypeName" :category="wanted.CategoryName" :address="wanted.Address" :transType="wanted.TTName" :wantedId="wanted.WantedID"/>
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-prev outside" slot="button-prev" v-cloak v-show="wanted.count > 3">
                    <i class="fas fa-chevron-left"></i>
                </div>
                <div class="swiper-button-next outside" slot="button-next" v-cloak v-show="wanted.count > 3">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import WantedCard from '../../../Utils/WantedCard.vue';

export default {
    props: ['property'],
    data () {
        return {
            wantedLoaded: false,
            wanted: null,
            pagination: false,
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    nextEl: '.wanted-list .swiper-button-next',
                    prevEl: '.wanted-list .swiper-button-prev'
                },
                breakpoints: {
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    }
                }
            }
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        WantedCard
    },
    mounted(){
        this.loadWanted();
    },
    methods: {
        redirectMe: function(name, prop){
            window.open(`/listing/view/${slugify(name)}/${prop}`)
        },
        loadWanted(){
            let baseUrl = process.env.MIX_BASE_URL_API;
            let config = {
                method: 'get',
                url: `${baseUrl}/wanted/getAllWanted`,
                params: {
                    latitude: `${Number(this.property.Latitude)}`,
                    longitude: `${Number(this.property.Longitude)}`,
                    limit: 10,
                    page: 1,
                    radius: 3000
                }
            };
            axios(config)
                .then(response => {
                    this.wanted = response.data;
                    this.wantedLoaded = true;
                })
            .catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>
