<template>
    <div>
        <div class="bg-gray-200">
            <swiper class="slider-property swiper" ref="propertySwiper" :options="swiperOptions" @slideChange="swiperChange">
                <swiper-slide v-for="photo in photos" :key="photo.PPhotoID">
                    <div class="h-230px md:h-323px">
                        <img :src="photo.NewURL" class="w-auto h-full mx-auto" v-bind:class="photo.PPhotoID" @error="setAltImg"/>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev">
                    <i class="fas fa-chevron-left"></i>
                </div>
                <div class="swiper-button-next" slot="button-next">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </swiper>
        </div>

        <div class="w-full overflow-x-auto">
            <div class="flex py-2 w-max space-x-3">
                <div class="thumb-slider-property" :class="activeSlide === index ?  'active' : ''" v-for="(photo, index) in photos" :key="photo.PPhotoID" @click="changeSlide(index)">
                    <img :src="thumbnail(photo.URL)" class="w-auto h-full mx-auto" v-bind:class="photo.PPhotoID" @error="setAltImg">
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
    props: [
        'photos'
    ],
    data() {
        return {
            activeSlide: 0,
            swiperOptions: {
                speed: 1000,
                slidesPerView: 1,
                navigation: false,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    type: "bullets"
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    computed: {
        swiper() {
            return this.$refs.propertySwiper.$swiper;
        }
    },
    methods: {
        setAltImg: function (event) {
            event.target.src = '/img/plce/plce1.jpg'
        },
        imgUrls: function (url) {
            return `/storage/listing/${url}`;
        },
        changeSlide: function (i) {
            this.swiper.slideTo(i, 1000);
        },
        swiperChange(){
            this.activeSlide = this.swiper.activeIndex;
        },
        thumbnail(url){
            return `/storage/listing_thumbnail/${url}`;
        }
    }
}
</script>
