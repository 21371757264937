<template>
    <div v-if="projectLoaded && projects.total > 0">
        <SliderRelated section-title="Closeby Proyek" :strings='this.$root.$data.strings' :projects="projects.projects"></SliderRelated>
    </div>
</template>

<script>
import SliderRelated from './Slider.vue';

export default {
    props: ['property'],
    data () {
        return {
            projectLoaded: false,
            projects: null
        }
    },
    components: {
        SliderRelated
    },
    mounted(){
        this.loadListing();
    },
    methods: {
        loadListing(){
            let baseUrl = process.env.MIX_BASE_URL_API;
            let config = {
                methods: 'get',
                url: `${baseUrl}/v1/primary_project/related`,
                params: {
                    latitude: `${Number(this.property.Latitude)}`,
                    longitude: `${Number(this.property.Longitude)}`,
                    radius: 3
                },
                headers: { }
            };

            axios(config)
                .then(response => {
                    this.projects = response.data;
                    this.projectLoaded = true;
                    })
            .catch(function (error) {
                console.log(error);
            });
        },
    }
}
</script>

<style>

</style>
