const { default: axios } = require("axios");

function navScrollAnimation() {
    var cbNavbarScroll = document.getElementById("nav-scroll");
    if (typeof cbNavbarScroll === "object") {
        if (
            document.body.scrollTop >= 300 ||
            document.documentElement.scrollTop >= 300
        ) {
            cbNavbarScroll.classList.add("scroll");
        } else {
            cbNavbarScroll.classList.remove("scroll");
        }
    }
}



module.exports = {
    navScrollAnimation
}
