<template>
    <div v-show="isActive" v-if="isActive">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        name: { required: true },
        selected: { default: false },
        authStatus: { default: null }
    },
    data() {
        return { isActive: false }
    },
    computed: {
        href() {
            return '#' + this.name.toLowerCase().replace(/ /g , '-');
        }
    },
    mounted() {
        this.isActive = this.selected;
    },
    methods: {

    }
}
</script>

<style>

</style>
