// your code here
function initModalEvent(){
    const modal = document.querySelector(".cb-modal");
    const triggerOpen = document.querySelectorAll(".open-modal");
    const triggerClose = document.querySelectorAll(".close-modal");

    if (triggerOpen) {
        triggerOpen.forEach(toggle => {
            toggle.addEventListener("click", function(e) {
                e.preventDefault();
                let target = this.getAttribute("data-modal");
                if (
                    target === "#register-modal" ||
                    target == "#forgot-password-modal"
                ) {
                    document
                        .getElementById("login-modal")
                        .classList.remove("show");
                    toggleCbModal(target);
                } else if (target === "#login-modal") {
                    document
                        .getElementById("register-modal")
                        .classList.remove("show");
                    toggleCbModal(target);
                } else {
                    toggleCbModal(target);
                }
            });
        })
    }



    triggerClose.forEach(close => {
        closeModal(close);
    })
}

function toggleCbModal(target) {
    document.querySelector(target).classList.add('show');
    document.querySelector('body').classList.add('overflow-hidden');
}

function closeModal(a) {
    a.addEventListener('click', function () {
        document.querySelector(a.getAttribute('data-modal')).classList.remove('show');
        document.querySelector('body').classList.remove('overflow-hidden');
    })
}

export function loginModal(target) {
    toggleCbModal(target);
}

window.initModalEvent = initModalEvent;
