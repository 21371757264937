<template>
    <div>
        <div class="bg-gray-200 mb-2">
            <div class="slider-property swiper swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="photo in photos" :key="photo.PrimaryProjectPhotoID">
                        <div class="h-230px md:h-323px">
                            <img :src="photo.URL" class="w-auto h-full mx-auto" v-bind:class="photo.PrimaryProjectPhotoID" @error="setAltImg"/>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-prev">
                    <i class="fas fa-chevron-left"></i>
                </div>
                <div class="swiper-button-next">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </div>

        <div class="swiper slider-property-thumb swiper-container">
            <div class="swiper-wrapper">
                <div class="thumb-slider-property swiper-slide w-max" v-for="(photo) in photos" :key="photo.PrimaryProjectPhotoID">
                    <img :src="photo.URL" class="h-full w-auto" @error="setAltImg">
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Swiper from 'swiper';
export default {
    props: [
        'photos'
    ],
    mounted(){
        const thumbs = new Swiper(".slider-property-thumb", {
            spaceBetween: 10,
            slidesPerView: 'auto',
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });

        const swiper = new Swiper(".slider-property ", {
            speed: 1000,
            slidesPerView: 1,
            navigation: {
                nextEl: '.slider-property .swiper-button-next',
                prevEl: '.slider-property .swiper-button-prev'
            },
            thumbs: {
                swiper: thumbs,
            },
        });
    },
    methods: {
        setAltImg: function (event) {
            event.target.src = '/img/plce/plce1.jpg'
        }
    }
}
</script>
