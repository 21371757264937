<template>
    <div>
        <p
            v-text="item.label"
            @click="isActive = !isActive"
            class="font-semibold text-lg"
        ></p>
        <div v-if="item.searches.length > 0">
            <ul v-show="isActive" class="flex flex-col divide-y-4 divide-transparent">
                <li class="" v-for="area in item.searches" v-bind:key="area.id">
                    <a
                        @click="handleLink(area, $event)"
                        class="p-2 rounded-md w-full flex flex-row flex-nowrap hover:bg-gray-300 bg-white transition-colors items-center"
                    >
                        <img
                            src="https://mamikos.com/general/img/icons/ic_pin-location.svg"
                            alt=""
                            class="w-8 h-8"
                        />
                        <p class="font-semibold ml-2">{{ area.label }}</p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { storeAnalytic } from "../../api/analytics";

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        token: {
            type: String,
        },
    },
    data() {
        return {
            isActive: false,
        };
    },
    methods: {
        url(coord, slug = null) {
            coord = JSON.parse(coord);
            if (slug) {
                return `/${this.type}/${slug}/${coord.lat}/${coord.lng}`;
            }
            return `/${this.type}/${coord.lat}/${coord.lng}`;
        },
        handleLink(area, e) {
            e.preventDefault();
            let data = {
                id: area.id,
                token: this.token,
            };
            window.open(this.url(area.coords, area.slug), "_blank");
            storeAnalytic("csl", data)
                .then(() => {
                    // window.open(this.url(area.coords, area.slug), "_blank");
                    // console.info("analytic sent");
                    // window.location.href = this.url(area.coords, area.slug);
                })
                .catch((err) => {
                    console.error(err);
                    // alert("Your connection is lost, please try again later");
                });
        },
    },
};
</script>

<style></style>
