<template>
    <div v-if="listingLoaded && listing.count > 0">
        <SliderRelated section-title="Closeby Listing" :strings='this.$root.$data.strings' :popularlists="listing.data"></SliderRelated>
    </div>
</template>

<script>
import SliderRelated from './Slider.vue';

export default {
    props: ['property'],
    data () {
        return {
            listingLoaded: false,
            listing: null
        }
    },
    components: {
        SliderRelated
    },
    mounted(){
        this.loadListing();
    },
    methods: {
        loadListing(){
            let baseUrl = process.env.MIX_BASE_URL_API;
            let config = {
                url: `${baseUrl}/property/getAllListing`,
                params: {
                    latitude: `${Number(this.property.Latitude)}`,
                    longitude: `${Number(this.property.Longitude)}`,
                    sort_by: 'Closest',
                    limit: 10,
                    page: 1,
                    radius: 3000,
                    property_category: [this.property.CategoryID]
                }
            };

            axios(config)
                .then(response => {
                    this.listing = response.data;
                    this.listingLoaded = true;
                    })
            .catch(function (error) {
                console.log(error);
            });
        },
    }
}
</script>

<style>

</style>
