<template>
    <div>
        <div
        v-if="!isLoaded"
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-3 gap-y-3">
            <div
                class="card-props"
                v-for="index in skeletonsCount" :key="index"
            >
                <vue-skeleton-loader
                    type="rectangle"
                    width="100%"
                    height="200px"
                    animation="fade"
                />
            </div>
        </div>

        <div v-else-if="isLoaded && hasProperties">
            <div
                class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-3 gap-y-3">
                <div
                    class="card-props"
                    v-for="property in properties" :key="property.id"
                >
                    <card
                        :popular="property"
                        :type="type"
                        :token="token"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';
import Card from './Utils/Card.vue';

export default {
    components: { Card },
    props: [
        'ajaxUrl',
        'listingVerified',
        'token'
    ],
    components: {
        VueSkeletonLoader,
        Card
    },
    data() {
        return {
            isLoaded: false,
            skeletonsCount: 4,
            properties: []
        }
    },
    mounted(){
        this.getProperties();
    },
    methods: {
        load() {
            this.isLoaded = true;
        },
        getProperties(){
            const config = {
                method: 'get',
                url : this.ajaxUrl,
                params : {
                    sort_by: 'CreatedDate',
                    sort_direction: 'Desc',
                    limit_popular: 50,
                    properties: this.listingVerified
                }
            }

            axios(config).then(response => {
                this.properties = response.data.data;
                this.load();
            }).catch(error => {
                console.log(error);
            });
        },
        hasProperties(){
            return this.properties.length > 0;
        }
    }
}
</script>
