import axios from "axios";

// const baseUrl = process.env.MIX_CMS_BASE_URL_API;
const baseUrl = process.env.MIX_BASE_URL_API;

const analyticAxios = axios.create({
    baseURL: baseUrl
});

export function storeAnalytic(type = "", data = {}) {
    const form = new FormData();
    let value = data.value;
    form.append("type", type);
    form.append("sub_type", data.sub_type || "");
    form.append("page", data.page || "");
    form.append("id", data.id || "");
    form.append("token", data.token || "");
    form.append("bank_id", data.bank_id || "");

    return analyticAxios.post("/analytic/store", form);
    // return analyticAxios.post("/v1/analytic", form);
}
