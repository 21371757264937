/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require("./bootstrap");
require("./plugins");

window.Vue = require("vue").default;
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import VueContentPlaceholders from "vue-content-placeholders";

// import VueSweetalert2 from 'vue-sweetalert2';
import VueGeolocation from "vue-browser-geolocation";
import * as VueGoogleMaps from "gmap-vue";

import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver, extend } from "vee-validate";

import VueTippy, { TippyComponent } from "vue-tippy";

//Axios
// For common config
// axios.defaults.headers.post["Content-Type"] = "application/json";
let httpApi = axios.create({
    baseURL: window.globalOptions.apiBaseUrl
});
// let customAxios = axios.create({
//     baseURL: "https://some-custom-domain.example/api/"
// });

//Global options
Vue.prototype.$httpApi = httpApi;
Vue.prototype.$userToken = window.globalOptions.userToken;
// Vue.prototype.$customAxios = customAxios;

Vue.use(VueContentPlaceholders);

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

extend("array_min_max_length", {
    params: ["array_length", "min", "max"],
    validate: (value, { array, min, max }) => {
        let message;
        if (array_length < min) {
            message = `Minimum ${min} Item Selected`;
            return false;
        } else if (array_length < max) {
            message = `Maximum ${max} Item Selected`;
            return false;
        }
        return true;
    },
    message: "{message}"
});

import VueToast from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";
import cms from "./api/cms";

Vue.use(VueToast);

// Vue.use(VueSweetalert2);

Vue.use(VueGoogleMaps, {
    load: {
        // key: "AIzaSyC20CtJVqnVdN5APa1-iV8OlgL6tuaJNwE",
        // key: "AIzaSyAJb_md0c8ZPCOYgYKsUBR5fkcgkh35dSQ",
        key: process.env.MIX_GOOGLE_MAPS_API_KEY,
        libraries: "places",
        v: "quarterly"
        // v: "3.46",
    },
    installComponents: true
});

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        authToken: null,
        search: null
    },
    mutations: {
        setSearch(state, data) {
            // mutate state
            state.search = data;
        }
    }
});

// Vue.component('map-listing', require('./components/Listing/MapListing.vue').default);
Vue.component(
    "map-listing",
    require("./components/Listing/Pages/Map.vue").default
);

Vue.component(
    "navigation-component",
    require("./components/Layout/NavigationComponent.vue").default
);
Vue.component(
    "footer-component",
    require("./components/Layout/FooterComponent.vue").default
);
Vue.component(
    "example-component",
    require("./components/ExampleComponent.vue").default
);
Vue.component("loading-card", require("./components/Loading/Card.vue").default);
Vue.component(
    "popular-list-component",
    require("./components/PopularListing/PopularListWrapper.vue").default
);
Vue.component(
    "project-recommend-component",
    require("./components/ProjectRecommend/ProjectRecommended.vue").default
);
Vue.component(
    "hero-component",
    require("./components/Hero/HeroComponent.vue").default
);
Vue.component(
    "tabs-component",
    require("./components/Tabs/TabMenuComponent.vue").default
);
Vue.component(
    "tab-component",
    require("./components/Tabs/TabContentComponent.vue").default
);

Vue.component(
    "show-listing",
    require("./components/Listing/ShowListingComponent.vue").default
);
Vue.component(
    "show-wanted",
    require("./components/Wanted/ShowWantedComponent.vue").default
);
Vue.component(
    "show-project",
    require("./components/Project/ProjectShowComponent.vue").default
);

Vue.component(
    "project-unit",
    require("./components/Project/UnitSlider.vue").default
);

Vue.component(
    "adds-component",
    require("./components/Services/Adds/AddsComponent.vue").default
);

Vue.component(
    "project-page-component",
    require("./components/ProjectsPage/PageLayout.vue").default
);
Vue.component(
    "city-dropdown",
    require("./components/Dropdown/CityDropdownComponent.vue").default
);
Vue.component(
    "related-show-page-component",
    require("./components/Services/Related/Layout.vue").default
);

Vue.component(
    "related-listing",
    require("./components/Services/Related/Listing/Component.vue").default
);
Vue.component(
    "related-wanted",
    require("./components/Services/Related/Wanted/Component.vue").default
);
Vue.component(
    "related-project",
    require("./components/Services/Related/Project/Component.vue").default
);

Vue.component(
    "faq-component",
    require("./components/Utils/FaqAccordion.vue").default
);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.component(
    "create.listing-component",
    require("./components/Listing/CreateListingComponent.vue").default
);
Vue.component(
    "create.wanted-component",
    require("./components/Wanted/CreateWantedComponent.vue").default
);

Vue.component(
    "change-password-component",
    require("./components/User/ChangePassword.vue").default
);
Vue.component(
    "notification-component",
    require("./components/User/Notifications/WrapperComponent.vue").default
);
Vue.component("login-page", require("./pages/Login.vue").default);
Vue.component("register-page", require("./pages/Register.vue").default);

Vue.component(
    "login-form",
    require("./components/User/Form/LoginComponent.vue").default
);

Vue.component(
    "listing-selection-component",
    require("./components/Listing/ListingSelection.vue").default
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//Global methods
Vue.mixin({
    methods: {
        getSearchMenu() {
            return this.$httpApi
                .get("/searchGroup")
                .then(res => {
                    return res;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        fallbackPhoto(event) {
            let srcFallback = event.target.getAttribute("srcFallback");
            if (!srcFallback) {
                srcFallback = "/img/plce/plce1.jpg"; // Default fallback image if custom attribute not provided
            }
            event.target.src = srcFallback;
        }
    }
});

const app = new Vue({
    el: "#app",
    store,
    data: {
        geolocationStatus: false,
        title: "Hello",
        strings: {},
        propertyType: "listing",
        showModal: false,
        area: null
    },
    mounted() {
        // cms.setAxios();
        // cms.getSearchMenu().then(res => {
        //     this.area = res.data;
        // });

        this.getSearchMenu().then(res => {
            this.area = res.data.data;
        });
    },
    computed: {
        apiBaseUrl() {
            return window.apiBaseUrl;
        }
    },
    methods: {
        openModal: function(name) {
            this.showModal = true;
            this.propertyType = name;
        },
        hideModal() {
            this.showModal = false;
        },
        changeTab: function(name) {
            this.propertyType = name;
        },
        goToPage() {
            this.checkLocationPermission();
        },

        checkLocationPermission() {
            navigator.geolocation.getCurrentPosition(
                position => {
                    cookieLocationSetter(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                    userCookieLocation(`{
                        "lat": ${position.coords.latitude},
                        "lng": ${position.coords.longitude}
                    }`);
                    window.location.href = `/${this.propertyType}`;
                },
                err => {
                    alert(
                        "Gagal menentukan lokasi anda, sepertinya anda tidak mengizinkan kami untuk mendapatkan lokasi. Silahkan cari di daerah yang sudah ditentukan"
                    );
                    userCookieLocation(null);
                },
                {
                    enableHighAccuracy: true
                }
            );
        }
    }
});
