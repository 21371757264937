let categoryData = [
    {
        placeholder: true,
        text: "Pilih Kategori"
    },
    {
        value: 1,
        text: "Residensial"
    },
    {
        value: 2,
        text: "Komersial"
    },
    {
        value: 3,
        text: "Industrial"
    }
];

let propertyData = [
    {
        category_id: 1,
        text: "Rumah",
        value: 1,
        featured_value: [
            "building_size",
            "land_size",
            "bedroom",
            "bathroom",
            "carpark"
        ]
    },
    {
        category_id: 1,
        text: "Apartemen",
        value: 2,
        featured_value: [
            "building_size",
            "land_size",
            // "bedroom",
            // "bathroom",
            // "carpark"
        ]
    },
    {
        category_id: 1,
        text: "Tanah",
        value: 3,
        featured_value: []
    },
    {
        category_id: 1,
        text: "Villa",
        value: 4,
        featured_value: []
    },
    {
        category_id: 2,
        text: "Ruko",
        value: 5,
        featured_value: []

    },
    {
        category_id: 2,
        text: "Kios",
        value: 6,
        featured_value: []

    },
    {
        category_id: 2,
        text: "Tanah",
        value: 7,
        featured_value: []

    },
    {
        category_id: 2,
        text: "Kantor",
        value: 8,
        featured_value: []

    },
    {
        category_id: 2,
        text: "Hotel",
        value: 9,
        featured_value: []

    },
    {
        category_id: 3,
        text: "Pabrik",
        value: 11,
        featured_value: []

    },
    {
        category_id: 3,
        text: "Gudang",
        value: 12,
        featured_value: []

    },
    {
        category_id: 3,
        text: "Tanah",
        value: 13,
        featured_value: []

    },
    {
        category_id: 3,
        text: "Lainnya",
        value: 14,
        featured_value: []

    },
    {
        category_id: 2,
        text: "Bangunan Stand-alone",
        value: 15,
        featured_value: []

    }
];

let listingBy = [
    {
        value: 1,
        text: "Pemilik"
    },
    {
        value: 2,
        text: "Agent"
    }
];

let listingTransaction = [
    {
        value: 1,
        text: "Dijual"
    },
    {
        value: 2,
        text: "Disewa"
    }
];

let wantedTransaction = [
    {
        value: 3,
        text: "Untuk Dibeli"
    },
    {
        value: 4,
        text: "Untuk Disewa (Jangka Pendek)"
    },
    {
        value: 5,
        text: "Untuk Disewa (Jangka Panjang)"
    },
];

let requesterType = [
    {
        value: 1,
        text: "Individual"
    },
    {
        value: 2,
        text: "Perusahaan"
    },
    {
        value: 3,
        text: "Agen yang ditunjuk"
    },
]

let ownerships = [
    {
        value: 1,
        text: "Hak Guna Bangunan",
    },
    {
        value: 2,
        text: "Hak Milik",
    },
    {
        value: 3,
        text: "Strata Title",
    },
    {
        value: 4,
        text: "Hak Pakai",
    },
    {
        value: 5,
        text: "Hak Sewa",
    },
    {
        value: 6,
        text: "Girik",
    }
];


export function getPropertyData() {
    return propertyData;
}

export function getCategoryData() {
    return categoryData;
}

export function getListingTransaction() {
    return listingTransaction;
}

export function getWantedTransaction() {
    return wantedTransaction;
}

export function getRequesterType() {
    return requesterType;
}

export function getOwnerships() {
    return ownerships;
}

export function getListingBy() {
    return listingBy;
}
