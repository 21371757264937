<template>
    <div class="flex w-full items-start justify-between mb-4">
        <div class="grid grid-cols-3 place-items-center w-96">
            <!-- <a href="#" class="w-full block p-2 scale-100 hover:scale-105 transform transition-all duration-500" @click.prevent="openBankModal('maybank')">
                <img src="/img/banks/logo-digital-maybank-112x51px.png" class="shadow" alt="">
            </a> -->
            <a
                href="#"
                v-for="(bank, index) in banks"
                class="block p-2 scale-100 hover:scale-105 transform transition-all duration-500"
                @click.prevent="openBankModal(index)"
                :key="`bank-${index}`"
            >
                <img :src="bank.logo" class="shadow-sm max-w-full" alt="" />
            </a>
        </div>

        <div class="cb-modal" v-bind:class="showBanksModal ? 'show' : ''">
            <!-- modal -->
            <div class="cb-modal-template-lg">
                <!-- modal header -->
                <div class="cbm-header">
                    <button class="close-modal" @click="showBanksModal = false">
                        <i class="fas fa-times text-xl"></i>
                    </button>
                </div>
                <!-- modal body -->
                <div class="cbm-body">
                    <div class="w-full px-4">
                        <h6
                            class="text-center text-2xl font-semibold text-gray-800 mb-5 font-display"
                        >
                            {{ banks[selected_index].name }}
                        </h6>
                        <div class="overflow-hidden">
                            <img
                                :src="desktopImage()"
                                alt=""
                                class="w-full mb-4 hidden md:block"
                            />
                            <img
                                :src="mobileImage()"
                                alt=""
                                class="w-full mb-4 block md:hidden"
                            />
                            <div
                                v-if="banks[selected_index].description"
                                v-html="banks[selected_index].description"
                                class="mb-3 text-base md:text-lg text-center"
                            ></div>
                            <p class="text-center">
                                <a
                                    :href="whatsapptext()"
                                    target="_blank"
                                    class="text-green text-base font-semibold hover:text-green-fallback transition-colors duration-1000 hover:underline"
                                    >Ketahui Lebih Lanjut!</a
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { storeAnalytic } from "../../api/analytics";

import banksData from "../../data/banks.json";

export default {
    props: ["property", "authStatus", "route"],
    data() {
        return {
            showBanksModal: false,
            banks: null,
            selected_index: 0,
            user_token: null,
        };
    },
    created() {
        this.banks = banksData;
        //create bank klik count
        this.banks.forEach((bank) => {
            bank.clickCount = 0;
        });

        if (this.authStatus.hasOwnProperty("login")) {
            if (this.authStatus.login == true) {
                this.user_token = this.authStatus.token || null;
            }
        }
    },
    methods: {
        mobileImage() {
            let url = this.banks[this.selected_index].mobile_image
                ? this.banks[this.selected_index].mobile_image
                : this.banks[this.selected_index].desktop_image;
            return url;
        },
        desktopImage() {
            let url = this.banks[this.selected_index].desktop_image;
            return url;
        },
        whatsapptext() {
            let text = this.banks[this.selected_index].message_plain;
            let windowurl = window.location.href;
            let fulltext = `${text} [${windowurl}]`;
            return `https://wa.me/628119771073?text=${encodeURI(fulltext)}`;
        },

        openBankModal(index) {
            this.selected_index = index;
            if (this.banks[index].clickCount < 1) {
                let data = {
                    id: this.getPropertyId(),
                    page: this.route,
                    token: this.user_token,
                    sub_type: banksData[index].slug,
                    bank_id: banksData[index].id,
                };
                storeAnalytic("cbp", data)
                    .then(() => {
                        this.banks[index].clickCount = 1;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            this.showBanksModal = true;
        },

        getPropertyId() {
            if (this.route == "wanted") {
                return this.property.WantedID;
            } else if (this.route == "listing") {
                return this.property.PropertyID;
            } else if (this.route == "project") {
                return this.property.PrimaryProjectID;
            }
        },
    },
};
</script>

<style></style>
