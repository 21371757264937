<template>
    <div class="flex flex-col md:flex-row h-full">
        <map-wrapper @map_event="getMap"
            :dataFiler="dataFiler"
            :singleMarker="singleMarker"
            ref="mapListing"
            />
        <card-wrapper
            @card_loaded="logger"
            @filter_changed="logger"
            @card_hover="createSingleMarker"
            @card_unhover="destroySingleMarker"
            :dataListing="dataListing"
            />
    </div>
</template>

<script>
import CardWrapper from '../CardListing.vue';
import MapWrapper from '../MapListing.vue';
import axios from 'axios';
export default {
    data(){
        return{
            dataListing: [],
            singleMarker: [],
            dataFiler: null
        }
    },
    components: {
        MapWrapper,
        CardWrapper
    },
    methods: {
        getMap(map, listing){
            this.dataListing = listing;
        },
        createSingleMarker(singleCard){
            this.singleMarker.push(singleCard);
        },
        destroySingleMarker(){
            this.singleMarker = []
        },
        logger(data){
            this.dataFiler = data;
            // this.fetchDataListing();
        }
    }

}
</script>

<style>

</style>
