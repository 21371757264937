import axios from "axios";

const baseUrlApi = process.env.MIX_BASE_URL_API || "https://api.closebuy.asia/api";

class AuthRepository {
    constructor() {
        this.baseUrl = baseUrlApi;
        this.AXIOS = null;
    }

    //axios create
    setAxios() {
        this.AXIOS = axios.create({
            baseURL: this.baseUrl,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Requested-With": "XMLHttpRequest"
            }
        });
    }

    //axios post reset password
    postResetPassword(email) {
        let data = new FormData();
        data.append("Email", email);
        return this.AXIOS.post("password/resetToEmail", data);
    }
}

export default new AuthRepository();
